<template>
  <div class="bluefy">
    <div class="white_dot"></div>
  </div>
</template>

<script>
export default {
  name: "BlueCircle",
};
</script>

<style scoped>
.bluefy {
  background: #00b4cc;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.white_dot {
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>