<template>
  <v-row justify="start">
    <v-dialog
      v-model="show"
      persistent
      max-width="630px"
    >
      <v-card
        class="rounded-max hide_overflow"
        flat
      >
        <div class="justify-end closer d-flex pa-1">
          <v-btn
            icon
            small
            @click="$emit('close')"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div style="min-height: 300px">
          <div class="pa-3">
            <div class="px-5">
              <v-row>
                <v-col
                  class="py-2"
                  cols="12"
                >
                  <label
                    for=""
                    style="color: black;"
                  ><b>Ringkasan Data Kesling Kunci</b></label>
                  <br><br>
                  <div
                    class="d-flex align-center "
                    style="border-top: 1px solid #ccc; border-bottom: 1px solid #ccc;"
                  >
                    <label
                      class="my-1 label-tipe-1 col-md-5"
                      for="limbah-organik"
                    >Jumlah Fasyankes</label>
                    <v-col class="text-right col-md-7">
                      <span style="font-weight: bold; margin-left: 5px;">{{ summary.total }}</span>
                    </v-col>
                  </div>

                  <div
                    class="d-flex align-center"
                    style=" border-bottom: 1px solid #ccc;"
                  >
                    <label
                      class="my-1 label-tipe-1 col-md-5"
                      for="limbah-organik"
                    >Sesuai Kriteria</label>
                    <v-col class="text-right col-md-7">
                      <span>({{ summary.meet_criteria_percentage }}%)</span>
                      <span style="font-weight: bold; margin-left: 5px;">{{ summary.meet_criteria }}</span>
                    </v-col>
                  </div>

                  <div
                    class="d-flex align-center"
                    style=" border-bottom: 1px solid #ccc;"
                  >
                    <label
                      class="my-1 label-tipe-1 col-md-5"
                      for="limbah-organik"
                    >Tidak Sesuai Kriteria</label>
                    <v-col class="text-right col-md-7">
                      <span>({{ summary.not_meet_criteria_percentage }}%)</span>
                      <span style="font-weight: bold; margin-left: 5px;">{{ summary.not_meet_criteria }}</span>
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <v-overlay
          :absolute="true"
          color="white"
          opacity="1"
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="#00b4cc"
          />
          <b class="color_default">Loading...</b>
        </v-overlay>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  
  <script>
import { mapState } from "vuex";

export default {
  name: "DialogSummaryEnvironmentalHealthData",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: "",
    },
    province_id: {
      type: [String, Number],
      default: "",
    },
    regency_id: {
      type: [String, Number],
      default: "",
    },
  },
    data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState({
      summary: (state) => state.report.monthly_summary,
    }),
  },
  watch: {
    show(val) {
      if (val) {
        this.fetchSummary();
      }
    },
  },
  methods: {
    async fetchSummary() {
      this.loading = true;
      const request_params = {
        date: this.date,
        province_id: this.province_id,
        regency_id: this.regency_id,
      };

      await this.$store.dispatch("report/getMonthlySummary", request_params);
      this.loading = false;
    },
  },
};
</script>
  
  <style scoped>
.side_left {
  width: 40%;
  height: 400px;
  border-radius: 0 !important;
}
.side_right {
  width: 60%;
}
.closer {
  width: 100%;
}
.temp_form {
  padding: 20px 25px;
}
.hide_overflow {
  overflow: hidden;
}
.family {
  font-family: "Montserrat";
}
.small-txt {
  font-size: small !important;
}
.bordered_input {
  border-width: 1px;
  border-color: rgba(215, 215, 215, 1);
  border-style: solid;
  border-radius: 4px;
}
</style>
  