<template>
  <div>
    <div v-if="generalData">
      <DetailSummaryHeader
        :valid="waste_report.service_statuses.sorting"
        title="Pemilahan"
        description="Dilakukan Pemilahan Limbah"
      />
    </div>
    <div
      v-if="generalData && generalData.data"
      class="container_profile mb-5"
    >
      <v-row>
        <v-col cols="8">
          <b class="small_txt">Total Limbah</b>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <b class="small_txt"> {{ generalData.total ? generalData.total : 0 }} </b>
        </v-col>
        <v-col cols="2">
          <b class="small_txt">kg/hari</b>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <b class="small_txt">Limbah Domestik</b>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <b class="small_txt"> {{ generalData.domestik ? generalData.domestik : 0 }} </b>
        </v-col>
        <v-col cols="2">
          <b class="small_txt">kg/hari</b>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <p class="small_txt ma-0 color_txt ml-5">
            <ul>
              <li>Limbah organik</li>
            </ul>
          </p>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <p class="small_txt ma-0 color_txt">
            {{
              generalData.data["Limbah Organik"].value
                ? generalData.data["Limbah Organik"].value
                : 0
            }}
          </p>
        </v-col>
        <v-col cols="2">
          <p class="small_txt ma-0 color_txt">
            kg/hari
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <p class="small_txt ma-0 color_txt ml-5">
            <ul>
              <li>Limbah non-Organik</li>
            </ul>
          </p>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <p class="small_txt ma-0 color_txt">
            {{
              generalData.data["Limbah Non-organik"].value
                ? generalData.data["Limbah Non-organik"].value
                : 0
            }}
          </p>
        </v-col>
        <v-col cols="2">
          <p class="small_txt ma-0 color_txt">
            kg/hari
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8">
          <b class="small_txt">Limbah Medis</b>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <b class="small_txt"> {{ generalData.medis ? generalData.medis : 0 }} </b>
        </v-col>
        <v-col cols="2">
          <b class="small_txt">kg/hari</b>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <b class="small_txt ml-3">Limbah medis Infeksius</b>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <b class="small_txt"> {{ generalData.infeksius }} </b>
        </v-col>
        <v-col cols="2">
          <b class="small_txt">kg/hari</b>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <p class="small_txt ma-0 color_txt ml-5">
            <ul>
              <li>Limbah infeksius</li>
            </ul>
          </p>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <p class="small_txt ma-0 color_txt">
            {{
              generalData.data["limbah Infeksius"].value
                ? generalData.data["limbah Infeksius"].value
                : 0
            }}
          </p>
        </v-col>
        <v-col cols="2">
          <p class="small_txt ma-0 color_txt">
            kg/hari
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <p class="small_txt ma-0 color_txt ml-5">
            <ul>
              <li>Limbah tajam</li>
            </ul>
          </p>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <p class="small_txt ma-0 color_txt">
            {{
              generalData.data["limbah Tajam"].value
                ? generalData.data["limbah Tajam"].value
                : 0
            }}
          </p>
        </v-col>
        <v-col cols="2">
          <p class="small_txt ma-0 color_txt">
            kg/hari
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <p class="small_txt ma-0 color_txt ml-5">
            <ul>
              <li>Limbah patologi</li>
            </ul>
          </p>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <p class="small_txt ma-0 color_txt">
            {{
              generalData.data["limbah Patologi"].value
                ? generalData.data["limbah Patologi"].value
                : 0
            }}
          </p>
        </v-col>
        <v-col cols="2">
          <p class="small_txt ma-0 color_txt">
            kg/hari
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <b class="small_txt ml-3">Limbah medis non-Infeksius</b>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <b class="small_txt"> {{ generalData.noninfeksius ? generalData.noninfeksius : 0 }} </b>
        </v-col>
        <v-col cols="2">
          <b class="small_txt">kg/hari</b>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <p class="small_txt ma-0 color_txt ml-5">
            <ul>
              <li>Limbah farmasi</li>
            </ul>
          </p>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <p class="small_txt ma-0 color_txt">
            {{
              generalData.data["limbah Farmasi"].value
                ? generalData.data["limbah Farmasi"].value
                : 0
            }}
          </p>
        </v-col>
        <v-col cols="2">
          <p class="small_txt ma-0 color_txt">
            kg/hari
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <p class="small_txt ma-0 color_txt ml-5">
            <ul>
              <li>Limbah kimia</li>
            </ul>
          </p>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <p class="small_txt ma-0 color_txt">
            {{
              generalData.data["limbah Kimia"].value
                ? generalData.data["limbah Kimia"].value
                : 0
            }}
          </p>
        </v-col>
        <v-col cols="2">
          <p class="small_txt ma-0 color_txt">
            kg/hari
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <p class="small_txt ma-0 color_txt ml-5">
            <ul>
              <li>Limbah sitotoksik</li>
            </ul>
          </p>
        </v-col>
        <v-col
          cols="2"
          class="text-right"
        >
          <p class="small_txt ma-0 color_txt">
            {{
              generalData.data["limbah Sitotoksik"].value
                ? generalData.data["limbah Sitotoksik"].value
                : 0
            }}
          </p>
        </v-col>
        <v-col cols="2">
          <p class="small_txt ma-0 color_txt">
            kg/hari
          </p>
        </v-col>
      </v-row>
    </div>

    <div>
      <DetailSummaryHeader
        :valid="waste_report.service_statuses.packaging"
        :title="question_groups.packaging.title"
        description="Sesuai Kriteria"
      />
    </div>
    <div class="container_profile mb-5">
      <v-row>
        <v-col
          v-for="item in question_groups.packaging.questions"
          :key="item.question"
          cols="4"
        >
          <div class="d-flex align-center">
            <v-icon
              color="primary"
              class="mr-2"
            >
              {{ getAnswer(question_groups.packaging.key, item.question) == "1" ? "mdi-checkbox-outline" : "mdi-checkbox-blank-outline" }}
            </v-icon>
            <p class="ma-0 color_txt small_txt">
              {{ item.question }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>

    <div>
      <DetailSummaryHeader
        :valid="waste_report.service_statuses.transportation"
        title="Pengangkutan"
        description="Sesuai Kriteria"
      />
    </div>
    <div class="container_profile mb-5">
      <v-row>
        <v-col
          v-for="item in question_groups.transportation.questions"
          :key="item.question"
          cols="4"
        >
          <div class="d-flex align-center">
            <v-icon
              color="primary"
              class="mr-2"
            >
              {{ getAnswer(question_groups.transportation.key, item.question) == "1" ? "mdi-checkbox-outline" : "mdi-checkbox-blank-outline" }}
            </v-icon>
            <p class="ma-0 color_txt small_txt">
              {{ item.question }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>

    <div>
      <DetailSummaryHeader
        :valid="waste_report.service_statuses.storage"
        title="Penyimpanan"
        description="Sesuai Kriteria"
      />
    </div>
    <div class="container_profile mb-5">
      <v-row>
        <v-col
          v-for="item in question_groups.storage.questions"
          :key="item.question"
          cols="12"
        >
          <div class="d-flex align-center">
            <v-icon
              color="primary"
              class="mr-2"
            >
              {{ getAnswer(question_groups.storage.key, item.question) == "1" ? "mdi-checkbox-outline" : "mdi-checkbox-blank-outline" }}
            </v-icon>
            <p class="ma-0 color_txt small_txt">
              {{ item.question }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>

    <div>
      <DetailSummaryHeader
        :valid="waste_report.service_statuses.processing"
        title="Pengolahan"
        description="Sesuai Kriteria"
      />
    </div>
    <div class="container_profile mb-5">
      <v-row>
        <v-col
          v-for="item in question_groups.processing.questions"
          :key="item.question"
          cols="4"
        >
          <div class="d-flex align-center">
            <v-icon
              color="primary"
              class="mr-2"
            >
              {{ getOptionalAnswer(item.options) ? "mdi-checkbox-outline" : "mdi-checkbox-blank-outline" }}
            </v-icon>
            <p class="ma-0 color_txt small_txt">
              {{ item.label_question ? item.label_question : item.question }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>

    <div>
      <DetailSummaryHeader
        :valid="waste_report.service_statuses.management"
        title="Pengelolaan"
        description="Sesuai Kriteria"
      />
    </div>

    <div class="container_profile">
      <v-row>
        <v-col cols="4">
          <div class="d-flex align-center">
            <v-icon
              color="primary"
              class="mr-2"
            >
              {{
                waste_report.service_statuses.sorting
                  ? "mdi-checkbox-outline"
                  : "mdi-checkbox-blank-outline"
              }}
            </v-icon>
            <p class="ma-0 color_txt small_txt">
              Pemilahan
            </p>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="d-flex align-center">
            <v-icon
              color="primary"
              class="mr-2"
            >
              {{
                waste_report.service_statuses.packaging
                  ? "mdi-checkbox-outline"
                  : "mdi-checkbox-blank-outline"
              }}
            </v-icon>
            <p class="ma-0 color_txt small_txt">
              Pewadahan
            </p>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="d-flex align-center">
            <v-icon
              color="primary"
              class="mr-2"
            >
              {{
                waste_report.service_statuses.transportation
                  ? "mdi-checkbox-outline"
                  : "mdi-checkbox-blank-outline"
              }}
            </v-icon>
            <p class="ma-0 color_txt small_txt">
              Pengangkutan
            </p>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="d-flex align-center">
            <v-icon
              color="primary"
              class="mr-2"
            >
              {{
                waste_report.service_statuses.storage
                  ? "mdi-checkbox-outline"
                  : "mdi-checkbox-blank-outline"
              }}
            </v-icon>
            <p class="ma-0 color_txt small_txt">
              Peyimpanan
            </p>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="d-flex align-center">
            <v-icon
              color="primary"
              class="mr-2"
            >
              {{
                waste_report.service_statuses.processing
                  ? "mdi-checkbox-outline"
                  : "mdi-checkbox-blank-outline"
              }}
            </v-icon>
            <p class="ma-0 color_txt small_txt">
              Pengolahan
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { COLORS } from "../../../../../constants/colors";
import DetailSummaryHeader from "./child/DetailSummaryHeader.vue";

const question_groups = {
  packaging: {
    title: "Pewadahan",
    key: "Spesifikasi pewadahan plastik",
    questions: [
      {
        question: "Tertutup",
        answer: "1",
      },
      {
        question: "Tidak bocor",
        answer: "1",
      },
    ],
  },
  transportation: {
    title: "Pengangkutan",
    key: "Spesifikasi Pengangkutan ke tempat penyimpanan",
    questions: [
      {
        question: "Khusus Alat angkut khusus limbah",
        answer: "1",
      },
      {
        question: "Tertutup",
        answer: "1",
      },
      {
        question: "Mudah dibersihkan",
        answer: "1",
      },
      {
        question: "Tidak bocor",
        answer: "1",
      },
      {
        question: "Waktu khusus",
        answer: "1",
      },
    ],
  },
  storage: {
    title: "Penyimpanan",
    key: "Ketersediaan ruang penyimpanan",
    questions: [
      {
        question:
          "Di Tempat Penyimpanan Sementara (TPS) Limbah B3 berizin (Dalam area fasyankes)",
        answer: "1",
      },
      {
        question:
          "Di Tempat Penyimpanan Sementara (TPS) Limbah B3 depo pemindahan berizin (diluar area fasyankes)",
        answer: "1",
      },
    ],
  },
  processing: {
    title: "Pengolahan",
    key: "Ketersediaan alat pengolah limbah",
    questions: [
      {
        label_question: "Diolah dengan insinerator",
        options: [
          {
            group: "Ketersediaan alat pengolah limbah",
            question: "Apakah terdapat pengolah limbah insinerator",
            answer: "1",
          },
          {
            group: "Spesifikasi pengolahan limbah",
            question: "Diolah dengan insinerator",
            answer: "1",
          },
        ],
      },
      {
        label_question: "Diolah dengan autoclave/microwave",
        options: [
          {
            group: "Ketersediaan alat pengolah limbah",
            question: "Apakah terdapat pengolah limbah autoclave/microwave",
            answer: "1",
          },
          {
            group: "Spesifikasi pengolahan limbah",
            question: "apakah terdapat alat pengolah limbah autoclave/microwave",
            answer: "1",
          },
        ]
      },
      {
        label_question: "Diolah dengan metode lainnya",
        options: [
          {
            group: "Spesifikasi pengolahan limbah",
            question: "Diolah dengan metode lainnya",
            answer: "1",
          },
        ]
      },
      {
        label_question: "Bekerja sama dengan pengolah limbah medis",
        options: [
          {
            group: "Spesifikasi pengolahan limbah",
            question: "Bekerja sama dengan pengolah limbah medis",
            answer: "1",
          },
        ]
      }
    ],
  },
};

export default {
  name: "GeneralStatistik",
  components: { DetailSummaryHeader },
  props: ["generalData", "profile", "created"],
  data() {
    return {
      COLORS,
      question_groups,
      configChart: null,
    };
  },
  computed: {
    ...mapState({
      waste_report: (state) => state.report.waste_report,
    }),
  },
  methods: {
    getOptionalAnswer(options) {
      for (let item of options) {
        if (this.getAnswer(item.group, item.question) == "1") {
          return true;
        }
      }

      return false;
    },
    getAnswer(group, question) {
      if (this.waste_report.questions && this.waste_report.questions[group]) {
        const item = this.waste_report.questions[group].find(
          (item) => item["question"] == question
        );

        return item ? item.answer : null;
      }

      return null;
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 14px;
  font-weight: 550;
  line-height: 20px;
  letter-spacing: -0.2px;
}

.fill_header {
  color: #000000;
}

.container_profile {
  padding: 15px;
  background: #fafafa;
}

.color-green-checkbox {
  color: #11bcc1;
}

.text-right {
  text-align: right !important;
}
</style>