<template>
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.4445 40.4446C40.4445 38.849 41.7378 37.5557 43.3334 37.5557H101.111C102.707 37.5557 104 38.849 104 40.4446C104 42.0401 102.707 43.3334 101.111 43.3334H43.3334C41.7378 43.3334 40.4445 42.0401 40.4445 40.4446Z"
      fill="#79BF7C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M66.4445 89.3828C85.7984 87.8202 101.271 75.7097 103.675 54.8891C103.89 53.0321 104 51.1058 104 49.1113H17.3334C17.3334 49.5392 17.3334 49.935 17.3334 50.3018V63.5558C17.3334 67.8891 22.3988 69.3336 24.5556 69.3336V104H66.4445V89.3828ZM60.6668 98.2225V84.0528L65.9794 83.6238C75.368 82.8657 83.3899 79.3557 89.0247 73.6733C93.5631 69.0967 96.8019 62.8642 97.854 54.8891H23.1112V63.1158C23.3339 63.2397 23.6366 63.3611 23.9764 63.4498C24.1497 63.4948 24.3028 63.5237 24.4218 63.5402C24.5123 63.5529 24.5617 63.5555 24.5677 63.5558H30.3334V98.2225H60.6668Z"
      fill="#79BF7C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M50.5556 83.7779H65.0001V89.5556H50.5556V83.7779ZM26 5.77778H8.66668C7.0712 5.77778 5.77778 7.0712 5.77778 8.66668V49.1112H28.8889V8.66668C28.8889 7.0712 27.5956 5.77778 26 5.77778ZM8.66668 0C3.88022 0 0 3.88022 0 8.66668V54.8889H34.6667V8.66668C34.6667 3.88022 30.7866 0 26 0H8.66668Z"
      fill="#79BF7C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.88892 11.5557H33.2223V17.3334H2.88892V11.5557Z"
      fill="#79BF7C"
    />
  </svg>

</template>

<script>
export default {
  name: "IconSanitation",
};
</script>