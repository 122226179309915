<template>
  <div class="header-container">
    <v-card
      class="card-filling-status rounded-max py-3 px-5"
      outlined
    >
      <div>
        <p class="card-filling-status__header">
          Status Pengisian
        </p>
        <p class="card-filling-status__description">
          <Dot
            :color="$getStatusColorHex(report.status)"
          />
          {{ $getReportStatusFormatted(report.status) }}
        </p>
      </div>
    </v-card>
    <v-card
      class="card-filling-status rounded-max py-3 px-5"
      outlined
    >
      <div>
        <p class="card-filling-status__header">
          Status Konfirmasi
        </p>
        <p class="card-filling-status__description">
          {{ $getVerificationStatusFormatted(report.verification_status) }}
        </p>
      </div>
    </v-card>
  </div>
</template>

<script>
import Dot from "../../icons/Dot.vue"

export default {
  name: "ReportStatusLegend",
  components: { Dot },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.header-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.card-filling-status {
  width: fit-content;
  background-color: #f6f5f8;
}

.card-filling-status__header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 148.4%;
  color: #404852;
  margin-bottom: 5px;
}

.card-filling-status__description {
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 148.4%;

  /* Neutral/black-300 */
  color: #586479;
  margin-bottom: 2px;
}
</style>