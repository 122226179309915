<template>
  <div>
    <v-card class="radius_intab custom_card pa-5">
      <div class="toolbar d-flex">
        <div
          v-if="provinces"
          class="toolbar_items"
        >
          <span class="dropdown-label">Provinsi </span>
          <v-select
            v-if="
              role === ROLES.PublicHealthService_Regency ||
                role === ROLES.PublicHealthService_Province
            "
            v-model="selected_province_id"
            placeholder="Provinsi"
            dense
            disabled
            item-text="provinsi_name"
            item-value="provinsi_id"
            :items="provinces"
            hide-details="auto"
            outlined
            class="mt-1"
            @change="getCities"
          />
          <v-select
            v-else
            v-model="selected_province_id"
            placeholder="Provinsi"
            dense
            item-text="provinsi_name"
            item-value="provinsi_id"
            :items="provinces"
            hide-details="auto"
            outlined
            class="mt-1"
            @change="getCities"
          />
        </div>
        <div class="toolbar_items">
          <span class="dropdown-label">Kabupaten/Kota</span>
          <v-select
            v-if="cities"
            v-model="selected_regency_id"
            placeholder="Kabupaten/Kota"
            dense
            :disabled="role === ROLES.PublicHealthService_Regency"
            item-text="kabupaten_name"
            item-value="kabupatenKota_id"
            :items="cities"
            hide-details="auto"
            outlined
            class="mt-1"
          />
          <v-select
            v-else
            placeholder="Kabupaten/Kota"
            dense
            disabled
            hide-details="auto"
            outlined
          />
        </div>
        <div class="toolbar_items">
          <span class="dropdown-label">Fasyankes</span>
          <v-select
            v-if="cities"
            v-model="selected_fasyankes_type"
            placeholder="Fasyankes"
            dense
            item-text="label"
            item-value="key"
            :items="fasyankes_types"
            hide-details="auto"
            outlined
            class="mt-1"
          />
        </div>
        <div class="align-self-end">
          <v-btn
            class="mx-2 rounded-lg custom_shadow"
            color="primary"
            height="40px"
            dark
            min-width="10px"
            @click="fetchReportsWithReset"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>

      <div style="width: 100%;">
        <v-row
          class="secondary-toolbar"
        >
          <div
            style="width: 310px;"
            class="mr-1"
          >
            <v-text-field
              v-model="search_query"
              flat
              label="Cari Fasyankes"
              class="custom_field"
              background-color="#F1FBFC"
              solo
              hide-details="auto"
              prepend-inner-icon="mdi-magnify"
              color="blue"
              @keydown="fetchReports"
            />
          </div>
          <div
            style="width: 141px;"
            class="ml-1"
          >
            <v-menu
              v-model="menu_limit"
              bottom
              :nudge-bottom="55"
              elevation="0"
            >
              <template #activator="{ on, attrs }">
                <v-card
                  class="pa-3 d-flex align-center"
                  outlined
                  v-bind="attrs"
                  height="100%"
                  v-on="on"
                >
                  <p class="mx-auto ma-0 color_txt">
                    Show : {{ limit }}
                  </p>
                  <v-icon class="mx-auto">
                    mdi-chevron-down
                  </v-icon>
                </v-card>
              </template>
              <v-card
                class="popup_radius"
                outlined
                elevation="0"
              >
                <v-list>
                  <v-list-item
                    link
                    @click="onUpdateLimit(10)"
                  >
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="onUpdateLimit(50)"
                  >
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="onUpdateLimit(100)"
                  >
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
          <div>
            <v-menu
              v-model="menu_service"
              bottom
              :nudge-bottom="55"
              elevation="0"
            >
              <template #activator="{ on, attrs }">
                <v-card
                  class="pa-3 d-flex align-center"
                  outlined
                  v-bind="attrs"
                  height="100%"
                  v-on="on"
                >
                  <p class="mx-auto ma-0 color_txt">
                    Filter Layanan
                  </p>
                  <v-icon class="ml-2 mx-auto">
                    mdi-filter
                  </v-icon>
                </v-card>
              </template>
              <v-card
                class="popup_radius px-3"
                outlined
                elevation="0"
              >
                <v-radio-group v-model="selected_service">
                  <v-radio
                    class="small-text"
                    label="Tidak Standar"
                    value="0"
                  />
                  <v-radio
                    label="Standar"
                    value="1"
                  />
                </v-radio-group>
              </v-card>
              <div />
            </v-menu>
          </div>
          <div>
            <v-menu
              v-model="menu_filter_domain"
              bottom
              :nudge-bottom="55"
              elevation="0"
            >
              <template #activator="{ on, attrs }">
                <v-card
                  class="pa-3 d-flex align-center"
                  outlined
                  v-bind="attrs"
                  height="100%"
                  v-on="on"
                >
                  <p class="mx-auto ma-0 color_txt">
                    {{ selected_filter_domain && selected_filter_domain != 0
                      ? `Filter Domain:  ${selected_filter_domain}`
                      : "Filter Domain" }}
                  </p>
                  <v-icon class="ml-2 mx-auto">
                    mdi-filter
                  </v-icon>
                </v-card>
              </template>
              <v-card
                class="popup_radius px-3"
                outlined
                elevation="0"
              >
                <v-radio-group v-model="selected_filter_domain">
                  <v-radio
                    class="small-text"
                    label="No Filter"
                    value="0"
                  />
                  <v-radio
                    class="small-text"
                    label="1"
                    value="1"
                  />
                  <v-radio
                    label="2"
                    value="2"
                  />
                  <v-radio
                    label="3"
                    value="3"
                  />
                  <v-radio
                    label="4"
                    value="4"
                  />
                  <v-radio
                    label="5"
                    value="5"
                  />
                </v-radio-group>
              </v-card>
              <div />
            </v-menu>
          </div>
        </v-row>
      </div>

      <v-skeleton-loader
        v-if="is_loading"
        type="table-tbody"
      />

      <div
        v-if="!is_loading && waste_reports_analysis"
        class="my-5"
      >
        <v-data-table
          :headers="headers"
          :items="waste_reports_analysis.data"
          :item-key="
            `waste_reports_analysis_${waste_reports_analysis.id}`
          "
          class="elevation-0 color_txt"
          :items-per-page="limit"
          hide-default-footer
          :item-class="getRowClasses"
        > 
          <template #[`item.fasyankes_name`]="{ item }">
            <p
              class="ma-0 text--gray25"
            >
              {{ item.fasyankes_name }}
            </p>
          </template> 
          <template #[`item.report_status`]="{ item }">
            <Dot :color="$getStatusColorHex(item.report_status)" />
          </template>
          <template #[`item.service_sorting`]="{ item }">
            <p class="ma-0 text-gray25">
              {{ item.service_sorting ? 1 : 0 }}
            </p>
          </template>
          <template #[`item.service_packaging`]="{ item }">
            <p class="ma-0 text-gray25">
              {{ item.service_packaging ? 1 : 0 }}
            </p>
          </template>
          <template #[`item.service_transportation`]="{ item }">
            <p class="ma-0 text-gray25">
              {{ item.service_transportation ? 1 : 0 }}
            </p>
          </template>
          <template #[`item.service_storage`]="{ item }">
            <p class="ma-0 text-gray25">
              {{ item.service_storage ? 1 : 0 }}
            </p>
          </template>
          <template #[`item.service_processing`]="{ item }">
            <p class="ma-0 text-gray25">
              {{ item.service_processing ? 1 : 0 }}
            </p>
          </template>
            
          <template #[`item.service_management`]="{ item }">
            <CriteriaBadge :valid="item.service_management" />
          </template>
          <template #[`item.report_created_at`]="{ item }">
            {{ $date(item.report_created_at).format("DD MMM YYYY") }}
          </template>
        </v-data-table>
      </div>
      <div class="d-flex justify-space-between pa-3">
        <div class="bottom-bar">
          <div class="px-2 mt-7">
            <ReportStatusLegend />
          </div>
          <div class="service-indicators mt-4 px-2">
            <h5>Kriteria Pengelolaan</h5>
            <div>0 : Tidak Sesuai Kriteria, 1 : Sesuai Kriteria</div>
          </div>
        </div>
        <div class="bottom-bar paginate-buttons">
          <div class="d-flex align-center pa-2 round_paginate">
            <v-btn
              small
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('prev')"
            >
              <v-icon small>
                mdi-arrow-left
              </v-icon>
            </v-btn>
            <div class="mx-3">
              <p class="ma-0 color_default">
                {{ page }} of
                {{ waste_reports_analysis.last_page }}
              </p>
            </div>
            <v-btn
              small
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('next')"
            >
              <v-icon small>
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="table-actions">
          <v-btn
            rounded
            dark
            color="#fff"
            class="text-capitalize"
            style="color: black;"
            @click="show_modal_summary = true"
          >
            Ringkasan Data
          </v-btn>
          <v-btn
            rounded
            dark
            color="primary"
            class="text-capitalize"
            @click="modalDialogRekapData = true"
          >
            Rekap Data
          </v-btn>
          <v-btn
            rounded
            dark
            color="primary"
            class="text-capitalize"
            @click="modalDialogUnduhData = true"
          >
            Unduh Data
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card
      v-if="view_detail"
      id="view-detail"
      class="mt-5 rounded-max custom_card pa-5"
    >
      <DetailDataFasyankes
        :id="selectedItem"
        :isian-data="isianData"
        :general-data="generalData"
        :grafik-timbulan="grafikTimbulan"
        :created="created"
        :profile="detail_profile"
      />
    </v-card>
    <RingkasanDataKeslingKunci
      :show="show_modal_summary"
      :date="date"
      :province_id="selected_province_id"
      :regency_id="selected_regency_id"
      @close="show_modal_summary = false"
    />
    <RekapData
      :modal-dialog-rekap-data="modalDialogRekapData"
      :form-type="'waste-management'"
      @close="modalDialogRekapData = false"
    />
    <UnduhData
      :modal-dialog-unduh-data="modalDialogUnduhData"
      :tipe-form="typeKesling"
      :tipe-date="'month'"
      @close="modalDialogUnduhData = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import DetailDataFasyankes from "../../../../components/Data/Detail/detailDataFasyankes.vue";
import RekapData from "../../../../components/Data/RekapData.vue";
import RingkasanDataKeslingKunci from "../../../../components/Data/RingkasanDataKeslingKunci.vue";
import UnduhData from "../../../../components/Data/UnduhData.vue";
import ReportStatusLegend from "../../../../components/Form/Legend/ReportStatus/index.vue";
import CriteriaBadge from "../../../../components/icons/CriteriaBadge.vue";
import Dot from "../../../../components/icons/Dot.vue";
import {
FASYANKES_TYPE,
FASYANKES_TYPE_NAME,
ROLES,
} from "../../../../constants/constant";

export default {
  name: "TableTabWaste",
  components: {
    Dot,
    CriteriaBadge,
    DetailDataFasyankes,
    UnduhData,
    RekapData,
    RingkasanDataKeslingKunci,
    ReportStatusLegend,
  },
  data() {
    return {
      ROLES, FASYANKES_TYPE, FASYANKES_TYPE_NAME,
      fasyankes_types: [
        { key: "", label: "Semua Fasyankes" },
        ...Object.entries(FASYANKES_TYPE_NAME).map(([key, label]) => ({
          key,
          label,
        })),
      ],
      is_loading: false,
      users: [],
      provinces: [],
      cities: [],
      selected_province_id: null,
      selected_regency_id: null,
      date: this.$date().format("YYYY-MM"),
      search_query: "",
      page: 1,
      limit: 10,
      menu_date: false,
      menu_limit: false,
      menu_service: false,
      menu_fasyankes_type: false,
      selected_fasyankes_type: "",
      headers: [
        {
          text: "Fasyankes",
          align: "start",
          value: "fasyankes_name",
          width: "300px",
        },
        {
          text: "Status",
          align: "center",
          value: "report_status",
        },
        {
          text: "Pemilahan",
          align: "center",
          value: "service_sorting",
          sortable: true,
          width: "5%",
        },
        {
          text: "Pewadahan",
          align: "center",
          value: "service_packaging",
          sortable: true,
          width: "5%",
        },
        {
          text: "Pengangkutan",
          align: "center",
          value: "service_transportation",
          sortable: true,
          width: "5%",
        },
        {
          text: "Penyimpanan",
          align: "center",
          value: "service_storage",
          sortable: true,
          width: "5%",
        },
        {
          text: "Pengolahan",
          align: "center",
          value: "service_processing",
          sortable: true,
          width: "5%",
        },
        {
          text: "Kriteria",
          align: "center",
          value: "service_management",
          width: "200px",
        },
        {
          text: "Tanggal",
          align: "center",
          value: "report_created_at",
        },
      ],
      fasyankes_profile: null,
      view_detail: false,
      generalData: null,
      isianData: null,
      grafikTimbulan: null,
      detail_profile: null,
      show_modal_summary: false,
      modalDialogRekapData: false,
      modalDialogUnduhData: false,
      selected_report: null,
      selected_report_id: null,
      selected_report_status: null,
      selected_service: null,
      typeKesling: "limbah-fasyankes",
      menu_filter_domain: false,
      selected_filter_domain: 0,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      profile: (state) => state.user.profile,
      waste_reports_analysis: (state) =>
        state.report.waste_reports_analysis,
    }),
  },
  watch: {
    selected_province_id() {
      if (
        this.selected_province_id &&
        this.selected_province_id !== "" &&
        this.role !== ROLES.PublicHealthService_Regency
      ) {
        this.selected_regency_id = null;
      }
    },
    selected_filter_domain(value) {
      if (value) {
        this.fetchReports();
      }
    },
  },
  
  async mounted() {
    await this.getProvinces();
    if (this.profile.access_right === ROLES.PublicHealthService_Province) {
      this.selected_province_id = parseInt(this.profile.provinsi);
      this.selected_role = ROLES.PublicHealthService_Regency;
      await this.getCities();
    }

    if (this.profile.access_right === ROLES.PublicHealthService_Regency) {
      this.selected_province_id = parseInt(this.profile.provinsi);
      this.selected_regency_id = parseInt(this.profile.kota);
      this.selected_role = ROLES.PublicHealthService_Regency;
      await this.getCities();
    }

    if (this.selected_province_id) {
      this.getCities(false);
    }

    this.fetchReports();
  },
  methods: {
    getProvinces() {
      this.$store.dispatch("region/getProvinces").then((data) => {
        this.provinces = data.data;
        const default_province = {
          provinsi_id: "",
          provinsi_name: "SEMUA (NASIONAL)",
        };
        this.provinces.unshift(default_province);
      });
    },
    getCities() {
      if (!this.selected_province_id || this.selected_province_id === "") {
        this.selected_regency_id = null;
        this.cities = [];
        return;
      }
      const payload = {
        provinsi_id: this.selected_province_id,
      };
      this.$store.dispatch("region/getCities", payload).then((data) => {
        let cities = data.data;
        if (cities?.length > 0) {
          const all_city = {
            kabupatenKota_id: "",
            kabupaten_name: "SEMUA (KABUPATEN/KOTA)",
          };
          cities?.unshift(all_city);
        }
        this.cities = cities;
      });
    },
    async fetchReports() {
      this.is_loading = true;
      const request_params = {
        page: this.page,
        limit: this.limit,
        search_query: this.search_query,
        date: this.date,
        province_id: this.selected_province_id,
        regency_id: this.selected_regency_id,
        fasyankes_type: this.selected_fasyankes_type,
      };
      const res = await this.$store.dispatch(
        "report/getWasteManagementReportsAnalysis",
        request_params
      );
      if (this.selected_filter_domain) {
        this.waste_reports_analysis.data = this.waste_reports_analysis.data.filter(
          (item) => {
            return item?.valid_service_count >= this.selected_filter_domain;
          }
        );
      }
      this.is_loading = false;
    },
    async fetchReportsWithReset() {
      this.page = 1;
      this.limit = 10;
      this.search_query = "";
      this.fetchReports();
    },
    onUpdateLimit(limit) {
      this.menu_limit = false;
      this.limit = limit;
      this.fetchReports();
    },
    getRowClasses(item) {
      let idx = this.waste_reports_analysis.data.indexOf(item);
      if (idx % 2 == 0) {
        return "strip_blue";
      }
    },
    pagination(type) {
      if (type === "prev") {
        if (this.page > 1) {
          this.page--;
          this.fetchReports();
        }
      } else if (type === "next") {
        if (this.page < this.waste_reports_analysis.last_page) {
          this.page++;
          this.fetchReports();
        }
      }
    },
    async toDetail(item) {
      this.selectedItem = item.report_id;
      this.created = item.report_createTime;
      this.view_detail = true;
      // TODO: remove this fetchDetail after full migration to getWasteReportById 
      this.fetchDetail(item);
      await this.$store.dispatch("report/getWasteManagementReportById", item.report_id);
      this.getDetailProfile(item);
      this.getIsian(item);
      setTimeout(() => {
        this.$vuetify.goTo("#view-detail");
      }, 300);
    },
    fetchDetail(item) {
      let data = {
        path: "detail-data-limbah-fasyankes",
        id: item.report_id,
      };
      this.$store
        .dispatch("data/detailData", data)
        .then((data) => {
          this.generalData = data;
          let srcData = data.data;
          if (srcData) {
            // SUM OF DOMESTIK
            let organik = srcData["Limbah Organik"].value
              ? parseFloat(srcData["Limbah Organik"].value)
              : 0;
            let nonorganik = srcData["Limbah Non-organik"].value
              ? parseFloat(srcData["Limbah Non-organik"].value)
              : 0;
            let domestik = organik + nonorganik;

            // SUM OF INFEKSIUS
            let limbah_infeksius = srcData["limbah Infeksius"].value
              ? parseFloat(srcData["limbah Infeksius"].value)
              : 0;
            let tajam = srcData["limbah Tajam"].value
              ? parseFloat(srcData["limbah Tajam"].value)
              : 0;
            let patologi = srcData["limbah Patologi"].value
              ? parseFloat(srcData["limbah Patologi"].value)
              : 0;
            let infeksius = limbah_infeksius + tajam + patologi;

            // SUM OF NON INFEKSIUS
            let farmasi = srcData["limbah Farmasi"].value
              ? parseFloat(srcData["limbah Farmasi"].value)
              : 0;
            let kimia = srcData["limbah Kimia"].value
              ? parseFloat(srcData["limbah Kimia"].value)
              : 0;
            let sitotoksik = srcData["limbah Sitotoksik"]
              ? parseFloat(srcData["limbah Sitotoksik"].value)
              : 0;
            let radioaktif = srcData["limbah Radioaktif"].value
              ? parseFloat(srcData["limbah Radioaktif"].value)
              : 0;
            let noninfeksius = farmasi + kimia + sitotoksik + radioaktif;

            let medis = infeksius + noninfeksius;
            let total = domestik + medis;
            this.generalData["domestik"] = domestik;
            this.generalData["infeksius"] = infeksius;
            this.generalData["noninfeksius"] = noninfeksius;
            this.generalData["medis"] = medis;
            this.generalData["total"] = total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDetailProfile(item) {
      let id = item.report_id;
      this.$store.dispatch("data/profileHeader", id).then((data) => {
        if (data.header[0].nama_rs) {
          data["0"]["name"] = data.header[0].nama_rs;
        } else {
          data["0"]["name"] = data.header[0].nama_puskesmas;
        }
        this.detail_profile = data["0"];
        this.getTimbulan({
          report_id: item.report_id,
          user_id: data.header[0].user_id,
          date: this.$date().format("YYYY-MM-DD"),
        });
      });
    },
    getTimbulan(data) {
      this.$store
        .dispatch("data/timbulanLimbahFasyankes", data)
        .then((data) => {
          let res = data.data;
          let dataChart = null;
          let key = [];
          let val = [];
          res.forEach((el) => {
            key.push(el.month_name);
            if (el.timbulan_limbah_medis == null) {
              val.push(0);
            } else {
              val.push(el.timbulan_limbah_medis);
            }
          });
          dataChart = {
            val: val,
            key: key,
          };
          this.grafikMaker(dataChart);
        });
    },
    grafikMaker(data) {
      let options = {
        chart: {
          id: "bar",
          toolbar: {
            show: false,
          },
        },
        // colors: ["#008448"],
        plotOptions: {
          bar: {
            horizontal: false,
            // borderRadius: 5,
            columnWidth: "55%",
            // endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 3,
          colors: ["transparent"],
        },
        xaxis: {
          categories: data.key,
        },
        fill: {
          opacity: 1,
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      };
      let series = [
        {
          name: "Timbulan Limbah",
          data: data.val,
        },
      ];
      this.grafikTimbulan = {
        series: series,
        options: options,
      };
    },
    getIsian(item) {
      let body = {
        report_id: item.report_id,
      };
      this.$store
        .dispatch("formulir/getFormulirFasyankes", body)
        .then((data) => {
          this.isianData = data.data.limbah_fasyankes;
        });
    },
    row_classes(item) {
      let idx = this.reports.indexOf(item);
      if (idx % 2 == 0) {
        return "strip_blue";
      }
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Montserrat";
}

.v-btn__content {
  min-width: none !important;
  width: 30px !important;
}

.dropdown-label {
  font-weight: 500;
}

.paginate-buttons {
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
}

.round_paginate {
  height: max-content;
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}

.bottom-bar {
  width: calc(100% / 3);
}

.toolbar_items {
  width: calc(90% / 4);
  padding-right: 10px;
}

.table-actions {
  display: flex;
  gap: 10px;
}

.service-indicators h5 {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2px;
}

.service-indicators {
  margin-top: 20px;
  font-size: 12px;
}

.secondary-toolbar {
  display: flex;
  gap: 10px;
  
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
}
</style>
