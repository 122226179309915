<template>
  <div>
    <div class="detail-title mb-5">
      Kelola Limbah - <span class="detail-title_date">{{ $date(report.created_at).format('MMMM YYYY') }}</span>
    </div>

    <div>
      <FormDetailHeader :report="report" />
    </div>

    <div>
      <v-tabs class="pt-2">
        <v-tab class="tab-title">
          Ringkasan
        </v-tab>
        <v-tab class="tab-title">
          Hasil
        </v-tab>
        <v-tab class="tab-title">
          Timbulan Limbah
        </v-tab>

        <v-tab-item class="tab-item-container">
          <CriteriaStatus :valid="report.meet_criteria" />

          <GeneralFasyankes
            :general-data="generalData"
            :created="created"
            :profile="profile"
          />
        </v-tab-item>
        <v-tab-item class="tab-item-container">
          <isian-fasyankes
            :id="id"
            :isian-data="isianData"
            :created="created"
            :profile="profile"
          />
        </v-tab-item>
        <v-tab-item class="tab-item-container">
          <div
            v-if="users_yearly_waste_emergence"
            class="mt-5"
          >
            <apexchart
              height="300"
              type="bar"
              :options="graph_options"
              :series="generateGraphSeries()"
            />
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GeneralFasyankes from "./isianFormulir/generalStatistik/generalFasyankes.vue";
import IsianFasyankes from "./isianFormulir/isianFasyankes.vue";
import FormDetailHeader from "../../../components/Form/Header/index.vue";
import CriteriaStatus from "../../../components/Form/Criteria/index.vue";

export default {
  name: "DetailDataWasteReport",
  components: {
    FormDetailHeader,
    GeneralFasyankes,
    CriteriaStatus,
    IsianFasyankes,
  },
  props: ["generalData", "isianData", "id", "profile", "created"],
  data() {
    return {
      selected_tab: null,
      graph_options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        colors: ["#FF6384", "#36A2EB", "#4BC0C0"], // Assuming these are the colors for 'Limba Domestik', 'Limbah Noninfeksius', and 'Limbah Infeksius'
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
          },
        },
        xaxis: {
          categories: [
            "Januari",
            "Februari",
            "Maret",
            "April",
            "Mei",
            "Juni",
            "Juli",
            "Agustus",
            "September",
            "Oktober",
            "November",
            "Desember",
          ],
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return value + " Kg/hari";
            },
          },
        },
      },
      graph_series: [],
    };
  },
  computed: {
    ...mapState({
      report: (state) =>
        state.globalUI.page.table.selected_tab == "environmental-health-key"
          ? state.report.report
          : state.report.waste_report,
      users_yearly_waste_emergence: (state) =>
        state.report.users_yearly_waste_emergence,
    }),
  },
  methods: {
    generateGraphSeries() {
      // Initialize the arrays
      let domesticData = [];
      let medicInfectedData = [];
      let medicNonInfectedData = [];

      // Iterate over the data
      this.users_yearly_waste_emergence.forEach((item) => {
        // Check if waste_emergence is not null
        if (item.waste_emergence) {
          domesticData.push(item.waste_emergence.domestic || 0);
          medicInfectedData.push(item.waste_emergence.medic_infected || 0);
          medicNonInfectedData.push(
            item.waste_emergence.medic_non_infected || 0
          );
        } else {
          // If waste_emergence is null, push 0 into the arrays
          domesticData.push(0);
          medicInfectedData.push(0);
          medicNonInfectedData.push(0);
        }
      });

      // Create the series
      const series = [
        {
          name: "Limbah Domestik",
          data: domesticData,
        },
        {
          name: "Limbah Noninfeksius",
          data: medicNonInfectedData,
        },
        {
          name: "Limbah Infeksius",
          data: medicInfectedData,
        },
      ];

      this.graph_series = series;
      return series;
    },

  },
};
</script>

<style scoped>
.detail-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #404852;
}

.detail-title_date {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #404852;
}

.custom_input {
  border-radius: 8px;
  width: 150px;
}

.tab-title {
  margin-top: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #404852;
}

.detil_active {
  font-weight: bold !important;
}
.detil_unactive {
  color: #00b4cc !important;
}
</style>