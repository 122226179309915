<template>
  <v-row justify="start">
    <v-dialog
      v-model="modalDialogRekapData"
      persistent
      max-width="630px"
    >
      <v-card
        class="rounded-max hide_overflow"
        flat
      >
        <div class="closer d-flex justify-end pa-1">
          <v-btn
            icon
            small
            @click="$emit('close')"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div style="min-height: 500px">
          <div class="pa-3">
            <div class="px-5">
              <v-row>
                <v-col
                  class="py-2"
                  cols="12"
                >
                  <label
                    for=""
                    class=""
                    style="color: black"
                  ><b>Rekap Data</b></label>
                  <br><br>
                  <div class="d-flex align-center per-form-added">
                    <label class="label-tipe-1 col-md-4">Tahun</label>
                    <div
                      class="toolbar_items"
                      style="width: 100%"
                    >
                      <v-select
                        v-model="selected_year"
                        :items="year_options"
                        outlined
                        hide-details="auto"
                        @input="menu = false"
                      />
                    </div>
                  </div>

                  <div class="d-flex align-center per-form-added">
                    <label class="label-tipe-1 col-md-4">Periode</label>
                    <div class="d-flex flex-row">
                      <div class="d-flex flex-column">
                        <div class="d-flex flex-row">
                          <div
                            v-for="period_type_option in period_type_options"
                            :key="period_type_option.period_type"
                            class="period-types"
                            :class="{ 'period-type-selected': period_type_option.period_type === selected_period_type }"
                            @click="showPeriodValueOptions(period_type_option.period_type)"
                          >
                            {{ period_type_option.label }}
                          </div>
                        </div>

                        <div>
                          <div
                            v-if="period_value_options"
                            style="background-color: #FAFBFC;"
                          >
                            <div
                              v-if="selected_period_type === 'month'"
                              class="grid-container"
                            >
                              <div
                                v-for="option in period_value_options"
                                :key="option.key"
                                class="result-item-months pt-2 pb-2"
                                :class="{ selected: selected_period_value === option.key }"
                                @click="selectPeriodValue(option.key)"
                              >
                                {{ option.label }}
                              </div>
                            </div>

                            <div v-else>
                              <div
                                v-for="option in period_value_options"
                                :key="option.key"
                                class="result-item pt-2 pb-2"
                                :class="{ selected: selected_period_value === option.key }"
                                @click="selectPeriodValue(option.key)"
                              >
                                {{ option.label }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="role === ROLES.Administrator || role === ROLES.MinistryOfHealth"
                    class="d-flex align-center per-form-added"
                  >
                    <label
                      class="label-tipe-1 col-md-4"
                      for="limbah-organik"
                    >Provinsi</label>
                    <div
                      class="toolbar_items"
                      style="width: 100%"
                    >
                      <v-select
                        v-model="selected_province_id"
                        placeholder="Provinsi"
                        dense
                        item-text="provinsi_name"
                        item-value="provinsi_id"
                        :items="provinces"
                        hide-details="auto"
                        outlined
                        @change="getCity"
                      />
                    </div>
                  </div>
                  <div class="d-flex align-center per-form-added">
                    <label
                      class="label-tipe-1 col-md-4"
                      for="limbah-organik"
                    >Kabupaten/Kota</label>
                    <div
                      class="toolbar_items"
                      style="width: 100%"
                    >
                      <v-select
                        v-if="regencies"
                        v-model="selected_regency_id"
                        placeholder="Kabupaten/Kota"
                        dense
                        item-text="kabupaten_name"
                        item-value="kabupatenKota_id"
                        :items="regencies"
                        :readonly="regencies <= 0"
                        hide-details="auto"
                        outlined
                      />
                    </div>
                  </div>
                  <div class="d-flex align-center per-form-added">
                    <label
                      class="label-tipe-1 col-md-4"
                      for="limbah-organik"
                    >Fasyankes</label>
                    <div
                      class="toolbar_items"
                      style="width: 100%"
                    >
                      <v-select
                        v-model="selected_fasyankes_type"
                        placeholder="Semua"
                        dense
                        item-text="nama_fasyankes"
                        item-value="fasyankes"
                        :items="listFasyankes"
                        hide-details="auto"
                        outlined
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
              <br><br>
              <div class="d-flex justify-center mt-1 mb-3">
                <v-btn
                  dark
                  depressed
                  color="#00b4cc"
                  class="text-capitalize"
                  style="border-radius: 25px"
                  :disabled="canDownload || loading"
                  @click="download()"
                >
                  <div v-if="loading">
                    <v-progress-circular
                      indeterminate
                      class="mr-2"
                      color="#00b4cc"
                    />
                    <b class="color_default">Sedang menyiapkan data...</b>
                  </div>
                  <div v-else>
                    <span>DOWNLOAD</span> 
                  </div>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  
<script>
import Cookies from "js-cookie";
import { mapState } from "vuex";
import { ROLES } from '../../constants/constant';

export default {
  name: "DialogRekapData",
  props: {
    modalDialogRekapData: {
      type: Boolean,
      default: false,
    },
    formType: {
      type: String,
      default: "",
    },
  },
  data() {
    const current_year = new Date().getFullYear();
    const past_year = current_year - 3; // Adjust the number of past years as needed
    const year_options = Array.from(
      { length: current_year - past_year + 1 },
      (_, i) => (past_year + i).toString()
    );
    return {
      ROLES,
      photoProfile: null,
      year_options,
      menu: false,
      menu1: false,
      email: "",
      provinces: [],
      regencies: [],
      listFasyankes: [
        { nama_fasyankes: "Semua", fasyankes: "" },
        { nama_fasyankes: "Rumah Sakit", fasyankes: 1 },
        { nama_fasyankes: "Puskesmas", fasyankes: 2 },
      ],
      selectedFasyankes: "",
      loading: false,
      period_type_options: [
        { period_type: "month", label: "Bulan" },
        { period_type: "quarter", label: "Triwulan" },
        { period_type: "semester", label: "Semester" },
        { period_type: "year", label: "Tahunan" },
      ],
      selectedButtonId: null,
      selectedButton: null,
      selectedIndex: null,
      months: [
        { key: 1, label: "Jan" },
        { key: 2, label: "Feb" },
        { key: 3, label: "Mar" },
        { key: 4, label: "Apr" },
        { key: 5, label: "May" },
        { key: 6, label: "Jun" },
        { key: 7, label: "Jul" },
        { key: 8, label: "Aug" },
        { key: 9, label: "Sep" },
        { key: 10, label: "Oct" },
        { key: 11, label: "Nov" },
        { key: 12, label: "Dec" },
      ],
      quaters: [
        { key: 1, label: "Triwulan 1 (Jan - Mar)" },
        { key: 2, label: "Triwulan 2 (Apr - Jun)" },
        { key: 3, label: "Triwulan 3 (Jul - Sep)" },
        { key: 4, label: "Triwulan 4 (Oct - Dec)" },
      ],
      semesters: [
        { key: 1, label: "Semester 1 (January - June)" },
        { key: 2, label: "Semester 2 (July - December)" },
      ],
      period_value_options: null,
      selected_year: year_options[year_options.length - 1],
      selected_period_type: "",
      selected_period_value: "",
      selected_province_id: "",
      selected_regency_id: "",
      selected_fasyankes_type: "",
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      person: (state) => state.user.profile,
    }),
    canDownload() {
      if (this.selected_period_type === '') {
        return true;
      }

      if (this.selected_period_type !== 'year' && this.selected_period_value === '') {
        return true;
      }

      return false;
    }  
  },

  async mounted() {
    let user_id = Cookies.get("id");
    await this.$store.dispatch("user/getProfile", user_id);

    if (this.role === ROLES.Administrator || this.role === ROLES.MinistryOfHealth) {
      this.getProvince();
    } else {
      this.selected_province_id = this.person.provinsi;

      this.getCity();
    }
  },
  methods: {
    showPeriodValueOptions(period_type) {
      this.selected_period_type = period_type;

      switch (period_type) {
        case "month":
          this.period_value_options = this.months;
          break;
        case "quarter":
          this.period_value_options = this.quaters;
          break;
        case "semester":
          this.period_value_options = this.semesters;
          break;
        default:
          this.period_value_options = null;
      }
    },
    selectPeriodValue(period_value) {
      this.selected_period_value = period_value;
    },

    getProvince() {
      this.$store.dispatch("region/getProvince").then((data) => {
        this.provinces = data.data;
        let nasional = {
          provinsi_id: "",
          provinsi_name: "Semua (Nasional)",
        };
        this.provinces.unshift(nasional);
      });
    },
    getCity() {
      this.selected_regency_id = "";
      let data = {
        provinsi_id: this.selected_province_id,
      };
      this.$store.dispatch("region/getCity", data).then((data) => {
        let option_all = {
          kabupatenKota_id: "",
          kabupaten_name: "Semua",
        };

        data.data?.unshift(option_all);
        this.regencies = data.data;
      });
    },
    download() {
      if (this.selected_period_type === 'year') {
        this.selected_period_value = 1;
      }

      const body = {
        year: this.selected_year,
        period_type: this.selected_period_type,
        period_value: this.selected_period_value,
        province_id: this.selected_province_id,
        regency_id: this.selected_regency_id,
        fasyankes_type: this.selected_fasyankes_type,
        form_type: this.formType,
      };
      
      this.loading = true;

      this.$store
        .dispatch("report/download", body)
        .then((data) => {
          const file_path = data.file_path;
          window.open(file_path, "_blank");
        })
        .catch((err) => {
          console.log("err download", err);
        })
        .finally(() => {
          // Set loading to false once the API call is complete
          this.loading = false;
        });
    },
  },
};
</script>
  
<style scoped>
.period-types {
  margin: 5px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid transparent;
  border-color: #31b4cc;
  border-radius: 7px;
  color: #31b4cc;
}
.period-type-selected {
  background-color: #31b4cc;
  color: white;
}

.selected-button {
  border-color: blue;
}

.result-item {
  margin: 5px 15px 5px 15px;
  padding: 5px 8px 5px 8px;
  padding: 8px;
  background: #fafbfc; /* Set the background color here */
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  font-weight: 550;
}

.result-item-months {
  margin: 5px 15px 5px 15px;
  /* padding: 5px 8px 5px 8px; */
  padding: 8px;
  background: #fafbfc; /* Set the background color here */
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-weight: 550;
}

.result-item-months.selected {
  background: #c3eef5;
  color: black;
  border-radius: 7px;
}

.result-item.selected {
  background: #c3eef5;
  color: black;
  border-radius: 7px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 12px;
  padding-bottom: 12px;
}

.grid-item {
  margin: 5px;
  padding: 10px;
  background: #fff; /* Set the background color here */
  cursor: pointer;
}
.grid-item.selected {
  background: blue;
  color: black;
}

.side_left {
  width: 40%;
  height: 400px;
  border-radius: 0 !important;
}
.side_right {
  width: 60%;
}
.closer {
  width: 100%;
}
.temp_form {
  padding: 20px 25px;
}
.hide_overflow {
  overflow: hidden;
}
.family {
  font-family: Arial, Helvetica, sans-serif;
}
.small-txt {
  font-size: small !important;
}
.bordered_input {
  border-width: 1px;
  border-color: rgba(215, 215, 215, 1);
  border-style: solid;
  border-radius: 4px;
}
</style>
  