<template>
  <div :class="valid ? 'valid-criteria' : 'invalid-criteria'">
    {{ valid ? 'Sesuai Kriteria' : 'Tidak Sesuai Kriteria' }}
  </div>
</template>

<script>
export default {
  name: "CriteriaBadge",
  props: {
    valid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.valid-criteria {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;

  background: #e6f7ff;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: #00b4cc;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.invalid-criteria {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;

  background: #ffe7e6;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: #ff3838;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
</style>