<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.9858 0.0226271H15.5732C19.2115 -0.262441 21.8419 2.17774 21.5346 5.55294V14.4471C21.8419 17.8223 19.2115 20.2624 15.5732 19.9774H5.9858C2.3475 20.2624 -0.282898 17.8223 0.0243909 14.4471V5.55294C-0.282898 2.17774 2.3475 -0.262441 5.9858 0.0226271ZM15.4011 7.75367C15.5978 7.54842 15.6838 7.29756 15.6838 7.0353C15.6838 6.69321 15.5363 6.36253 15.2413 6.14588C15.0078 5.97484 14.7497 5.89502 14.4424 5.89502C14.0859 5.89502 13.7664 6.03186 13.5205 6.29412L8.92348 11.3569L7.90328 10.0228C7.66974 9.72633 7.31329 9.54389 6.90767 9.54389C6.64954 9.54389 6.41601 9.61231 6.20705 9.73774C5.86288 9.94299 5.67851 10.2623 5.67851 10.6614C5.67851 10.9008 5.75226 11.1289 5.89976 11.3341L7.80495 13.8541C8.05078 14.1734 8.40724 14.3558 8.82515 14.3558C9.18161 14.3558 9.51348 14.219 9.75931 13.9453L15.4011 7.75367Z"
      :fill="fill"
    />
  </svg>

</template>

<script>
export default {
  name: "CheckList",
  props: {
    fill: {
      type: String,
      default: "#31B4CC",
    },
  }
};
</script>