<template>
  <div style="padding-bottom: 20px">
    <div class="indicator-types">
      <div v-for="item in sortedData" :key="item.type" class="indicator-types__item">
        <div :style="{ backgroundColor: $getReportStatusColor(item.status) }" class="indicator-types__item_title">
          {{ $getReportStatusTranslation(item.status) }}
        </div>
        <div class="indicator-types__item_icon_container">
          <div v-if="item.type == 'water'">
            <IconWater />
            <div class="icon-label">Air</div>
          </div>
          <div v-else-if="item.type == 'sanitation'">
            <IconSanitation />
            <div class="icon-label" :style="{ color: COLORS.primary_green }">Sanitasi</div>
          </div>
          <div v-else-if="item.type == 'waste'">
            <IconWaste />
            <div class="icon-label" :style="{ color: COLORS.primary_red }">Limbah</div>
          </div>
          <div v-else-if="item.type == 'higiene'">
            <IconHigiene />
            <div class="icon-label" :style="{ color: COLORS.primary_purple }">Higiene</div>
          </div>
          <div v-else-if="item.type == 'cleaning'">
            <IconCleaning />
            <div class="icon-label" :style="{ color: COLORS.primary_pink }">Kebersihan</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IconCleaning from "../../../components/icons/Cleaning.vue";
import IconHigiene from "../../../components/icons/Higiene.vue";
import IconSanitation from "../../../components/icons/Sanitation.vue";
import IconWaste from "../../../components/icons/Waste.vue";
import IconWater from "../../../components/icons/Water.vue";
import { COLORS } from "../../../constants/colors";

export default {
  name: "FormIndicator",
  components: {
    IconWater,
    IconSanitation,
    IconCleaning,
    IconWaste,
    IconHigiene,
  },
  data() {
    return {
      COLORS,
    };
  },
  async mounted() {
    
  },
  computed: {
    ...mapState({
      report: (state) => state.report.report,
    }),
    sortedData() {
      const order = ['water', 'sanitation', 'higiene', 'waste', 'cleaning'];
      // make sure report is already fetched
      if (!this.report.data) return [];
      
      return this.report.data.slice().sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type))
      
    }
  },
};
</script>

<style scoped>
.indicator-types {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
}

.indicator-types>* {
  flex: 1 1 0px;
}


.indicator-types__item {
  border-radius: 13px;
  border: 1px solid rgba(215, 215, 215, 1);

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -0.2px;

  padding-top: 20px;
  padding-bottom: 20px;
  color: #31b4cc;
}

.indicator-types__item_title {
  display: flex;
  justify-content: center;

  background-color: #31b4cc;
  color: #fff;
  padding: 15px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 148.4%;

  color: #ffffff;

  text-transform: capitalize;

  margin-left: 10px;
  margin-right: 10px;
  border-radius: 8px;
}

.indicator-types__item_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  min-height: 200px;
}

.icon-label {
  margin-top: 10px;
  display: flex;
  justify-content: center;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 148.4%;
}
</style>
