<template>
  <div>
    <profile-detil-data :profile="profile" />
    <div class="mt-5 mx-3">
      <div class="d-flex justify-space-between">
        <p class="small_txt color_txt">
          {{ $date(created).format("MMMM YYYY") }}
        </p>
        <v-btn
          outlined
          small
          color="#00b4cc"
          @click="generatePdf"
        >
          <v-icon color="#00b4cc">
            mdi-printer
          </v-icon>
        </v-btn>
      </div>
      <print-fasyankes
        :id="id"
        ref="prt"
        :profile="profile"
        :created="created"
      />
      <!-- isian muali -->
      <div
        v-if="limbah_padat && limbah_cair && sumber_daya && alur"
        class="mt-5"
      >
        <vue-html2pdf
          ref="html2Pdf"
          :show-layout="true"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="hee hee"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="100%"
        >
          <div slot="pdf-content">
            <!-- TAB 1 -->
            <v-card
              color="#CFEEF2"
              flat
              class="mb-5"
            >
              <div class="fill_header white--text py-2 px-5">
                <b>1. Data Limbah Padat</b>
              </div>
            </v-card>
            <v-card
              class="rounded-max py-3 px-5 mb-5"
              outlined
            >
              <v-row>
                <v-col cols="8">
                  <div
                    class="d-flex align-center"
                    style="height: 100%"
                  >
                    <p class="ma-0 small_txt color_txt">
                      Apakah dilakukan pemilahan limbah domestik, medis tajam, dan medis infeksius pada wadah yang berbeda ?
                    </p>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-radio-group
                    v-model="
                      limbah_padat.limbah_padat[
                        'apakah_dilakukan_pemilahan_limbah_padat?'
                      ].dataPenyusun_desc
                    "
                    row
                    disabled
                    dense
                    hide-details
                    class="ma-0"
                  >
                    <v-radio
                      color="#00ccb6"
                      label="Ya"
                      value="1"
                    />
                    <v-radio
                      color="#00ccb6"
                      label="Tidak"
                      value="2"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card>

            <div
              v-if="
                limbah_padat.limbah_padat[
                  'apakah_dilakukan_pemilahan_limbah_padat?'
                ].dataPenyusun_desc == 1
              "
            >
              <v-card
                outlined
                class="pa-3 mb-4 rounded-max"
              >
                <div class="mb-3">
                  <b>Limbah Domestik</b>
                </div>
                <v-card
                  flat
                  class="pa-2 rounded-lg mb-4"
                  color="#F6F6F6"
                >
                  <v-row>
                    <v-col cols="9">
                      <div
                        class="d-flex align-center"
                        style="height: 100%"
                      >
                        <p class="ma-0 small_txt color_txt">
                          Apakah dilakukan pemilahan limbah domestik ?
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <v-radio-group
                        v-model="
                          limbah_padat.limbah_domestik[
                            'apakah_dilakukan_pemilahan_domestik'
                          ].dataPenyusun_desc
                        "
                        row
                        disabled
                        dense
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ya"
                          value="1"
                        />
                        <v-radio
                          color="#00ccb6"
                          label="Tidak"
                          value="2"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-card>
                <div
                  v-if="
                    limbah_padat.limbah_domestik[
                      'apakah_dilakukan_pemilahan_domestik'
                    ].dataPenyusun_desc == 1
                  "
                >
                  <v-row class="mb-3">
                    <v-col cols="3">
                      <div
                        class="d-flex align-center"
                        style="height: 100%"
                      >
                        <p class="ma-0 small_txt color_txt">
                          Timbulan limbah organik
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="7">
                      <v-card
                        class="d-flex align-center pl-2 px-5"
                        outlined
                        style="width: max-content"
                      >
                        <input
                          v-model="
                            limbah_padat.limbah_domestik['limbah_organik']
                              .dataPenyusun_desc
                          "
                          type="number"
                          disabled
                          class="pa-2 mr-1 custom_input"
                        >
                        <div class="icon-group">
                          kg/bulan
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3">
                      <div
                        class="d-flex align-center"
                        style="height: 100%"
                      >
                        <p class="ma-0 small_txt color_txt">
                          Timbulan limbah anorganik
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="7">
                      <v-card
                        class="d-flex align-center pl-2 px-5"
                        outlined
                        style="width: max-content"
                      >
                        <input
                          v-model="
                            limbah_padat.limbah_domestik['limbah_non_organik']
                              .dataPenyusun_desc
                          "
                          type="number"
                          disabled
                          class="pa-2 mr-1 custom_input"
                        >
                        <div class="icon-group">
                          kg/bulan
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
                <v-row
                  v-if="
                    limbah_padat.limbah_domestik[
                      'apakah_dilakukan_pemilahan_domestik'
                    ].dataPenyusun_desc == 2
                  "
                >
                  <v-col cols="3">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Timbulan total limbah domestik
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="7">
                    <v-card
                      class="d-flex align-center pl-2 px-5"
                      outlined
                      style="width: max-content"
                    >
                      <input
                        v-model="
                          limbah_padat.limbah_domestik[
                            'jumlah_total_limbah_domestik'
                          ].dataPenyusun_desc
                        "
                        type="number"
                        disabled
                        class="pa-2 mr-1 custom_input"
                      >
                      <div class="icon-group">
                        kg/bulan
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>

              <v-card
                outlined
                class="pa-3 mb-4 rounded-max"
              >
                <div class="mb-3">
                  <b>Limbah Medis</b>
                </div>
                <v-card
                  flat
                  class="pa-2 rounded-lg mb-4"
                  color="#F6F6F6"
                >
                  <v-row>
                    <v-col cols="9">
                      <div
                        class="d-flex align-center"
                        style="height: 100%"
                      >
                        <p class="ma-0 small_txt color_txt">
                          Apakah dilakukan pemilahan limbah medis ?
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <v-radio-group
                        v-model="
                          limbah_padat.limbah_medis[
                            'apakah_dilakukan_pemilahan_limbah_medis?'
                          ].dataPenyusun_desc
                        "
                        row
                        disabled
                        dense
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          color="#00ccb6"
                          label="Ya"
                          value="1"
                        />
                        <v-radio
                          color="#00ccb6"
                          label="Tidak"
                          value="2"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-card>

                <div
                  v-if="
                    limbah_padat.limbah_medis[
                      'apakah_dilakukan_pemilahan_limbah_medis?'
                    ].dataPenyusun_desc == 1
                  "
                >
                  <v-card
                    outlined
                    class="pa-3 mb-3 rounded-max"
                  >
                    <div class="mb-3">
                      <b class="color_default">Limbah Infeksius</b>
                    </div>
                    <v-card
                      flat
                      class="pa-2 rounded-lg mb-4"
                      color="#F6F6F6"
                    >
                      <v-row>
                        <v-col cols="9">
                          <div
                            class="d-flex align-center"
                            style="height: 100%"
                          >
                            <p class="ma-0 small_txt color_txt">
                              Apakah dilakukan pemilahan limbah medis infeksius ?
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            v-model="
                              limbah_padat.limbah_infeksius[
                                'apakah_dilakukan_pemilahan_infeksius'
                              ].dataPenyusun_desc
                            "
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <div
                      v-if="
                        limbah_padat.limbah_infeksius[
                          'apakah_dilakukan_pemilahan_infeksius'
                        ].dataPenyusun_desc == 1
                      "
                    >
                      <v-row>
                        <v-col cols="3">
                          <div
                            class="d-flex align-center"
                            style="height: 100%"
                          >
                            <p class="ma-0 small_txt color_txt">
                              Timbulan limbah infeksius
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="7">
                          <v-card
                            class="d-flex align-center pl-2 px-5"
                            outlined
                            style="width: max-content"
                          >
                            <input
                              v-model="
                                limbah_padat.limbah_infeksius['limbah_infeksius']
                                  .dataPenyusun_desc
                              "
                              type="number"
                              disabled
                              class="pa-2 mr-1 custom_input"
                            >
                            <div class="icon-group">
                              kg/bulan
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <div
                            class="d-flex align-center"
                            style="height: 100%"
                          >
                            <p class="ma-0 small_txt color_txt">
                              Timbulan limbah tajam
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="7">
                          <v-card
                            class="d-flex align-center pl-2 px-5"
                            outlined
                            style="width: max-content"
                          >
                            <input
                              v-model="
                                limbah_padat.limbah_infeksius['limbah_tajam']
                                  .dataPenyusun_desc
                              "
                              type="number"
                              disabled
                              class="pa-2 mr-1 custom_input"
                            >
                            <div class="icon-group">
                              kg/bulan
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <div
                            class="d-flex align-center"
                            style="height: 100%"
                          >
                            <p class="ma-0 small_txt color_txt">
                              Timbulan limbah patologi
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="7">
                          <v-card
                            class="d-flex align-center pl-2 px-5"
                            outlined
                            style="width: max-content"
                          >
                            <input
                              v-model="
                                limbah_padat.limbah_infeksius['limbah_patologi']
                                  .dataPenyusun_desc
                              "
                              type="number"
                              disabled
                              class="pa-2 mr-1 custom_input"
                            >
                            <div class="icon-group">
                              kg/bulan
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                    <div
                      v-if="
                        limbah_padat.limbah_infeksius[
                          'apakah_dilakukan_pemilahan_infeksius'
                        ].dataPenyusun_desc == 2
                      "
                    >
                      <v-row>
                        <v-col cols="3">
                          <div
                            class="d-flex align-center"
                            style="height: 100%"
                          >
                            <p class="ma-0 small_txt color_txt">
                              Timbulan total limbah medis infeksius
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="7">
                          <v-card
                            class="d-flex align-center pl-2 px-5"
                            outlined
                            style="width: max-content"
                          >
                            <input
                              v-model="
                                limbah_padat.limbah_infeksius['jumlah_total_limbah_infeksius']
                                  .dataPenyusun_desc
                              "
                              type="number"
                              disabled
                              class="pa-2 mr-1 custom_input"
                            >
                            <div class="icon-group">
                              kg/bulan
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>

                  <v-card
                    outlined
                    class="pa-3 mb-4 rounded-max"
                  >
                    <div class="mb-3">
                      <b class="color_default">Limbah Non-Infeksius</b>
                    </div>
                    <v-card
                      flat
                      class="pa-2 rounded-lg mb-4"
                      color="#F6F6F6"
                    >
                      <v-row>
                        <v-col cols="9">
                          <div
                            class="d-flex align-center"
                            style="height: 100%"
                          >
                            <p class="ma-0 small_txt color_txt">
                              Apakah dilakukan pemilahan limbah medis non infeksius ?
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <v-radio-group
                            v-model="
                              limbah_padat.limbah_non_infeksius[
                                'apakah_dilakukan_pemilahan_noninfeksius'
                              ].dataPenyusun_desc
                            "
                            row
                            disabled
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card>
                    <div
                      v-if="
                        limbah_padat.limbah_non_infeksius[
                          'apakah_dilakukan_pemilahan_noninfeksius'
                        ].dataPenyusun_desc == 1
                      "
                    >
                      <v-row>
                        <v-col cols="3">
                          <div
                            class="d-flex align-center"
                            style="height: 100%"
                          >
                            <p class="ma-0 small_txt color_txt">
                              Timbulan limbah farmasi
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="7">
                          <v-card
                            class="d-flex align-center pl-2 px-5"
                            outlined
                            style="width: max-content"
                          >
                            <input
                              v-model="
                                limbah_padat.limbah_non_infeksius['limbah_farmasi']
                                  .dataPenyusun_desc
                              "
                              type="number"
                              disabled
                              class="pa-2 mr-1 custom_input"
                            >
                            <div class="icon-group">
                              kg/bulan
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <div
                            class="d-flex align-center"
                            style="height: 100%"
                          >
                            <p class="ma-0 small_txt color_txt">
                              Timbulan limbah kimia
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="7">
                          <v-card
                            class="d-flex align-center pl-2 px-5"
                            outlined
                            style="width: max-content"
                          >
                            <input
                              v-model="
                                limbah_padat.limbah_non_infeksius['limbah_kimia']
                                  .dataPenyusun_desc
                              "
                              type="number"
                              disabled
                              class="pa-2 mr-1 custom_input"
                            >
                            <div class="icon-group">
                              kg/bulan
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <div
                            class="d-flex align-center"
                            style="height: 100%"
                          >
                            <p class="ma-0 small_txt color_txt">
                              Timbulan limbah sitotoksik
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="7">
                          <v-card
                            class="d-flex align-center pl-2 px-5"
                            outlined
                            style="width: max-content"
                          >
                            <input
                              v-model="
                                limbah_padat.limbah_non_infeksius['limbah_sitotoksik']
                                  .dataPenyusun_desc
                              "
                              type="number"
                              disabled
                              class="pa-2 mr-1 custom_input"
                            >
                            <div class="icon-group">
                              kg/bulan
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <div
                            class="d-flex align-center"
                            style="height: 100%"
                          >
                            <p class="ma-0 small_txt color_txt">
                              Timbulan limbah radioaktif
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="7">
                          <v-card
                            class="d-flex align-center pl-2 px-5"
                            outlined
                            style="width: max-content"
                          >
                            <input
                              v-model="
                                limbah_padat.limbah_non_infeksius['limbah_radioaktif']
                                  .dataPenyusun_desc
                              "
                              type="number"
                              disabled
                              class="pa-2 mr-1 custom_input"
                            >
                            <div class="icon-group">
                              kg/bulan
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                    <div
                      v-if="
                        limbah_padat.limbah_non_infeksius[
                          'apakah_dilakukan_pemilahan_noninfeksius'
                        ].dataPenyusun_desc == 2
                      "
                    >
                      <v-row>
                        <v-col cols="3">
                          <div
                            class="d-flex align-center"
                            style="height: 100%"
                          >
                            <p class="ma-0 small_txt color_txt">
                              Timbulan total limbah medis non infeksius
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="7">
                          <v-card
                            class="d-flex align-center pl-2 px-5"
                            outlined
                            style="width: max-content"
                          >
                            <input
                              v-model="
                                limbah_padat.limbah_non_infeksius['jumlah_total_limbah_non_infeksius']
                                  .dataPenyusun_desc
                              "
                              type="number"
                              disabled
                              class="pa-2 mr-1 custom_input"
                            >
                            <div class="icon-group">
                              kg/bulan
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </div>
              </v-card>
            </div>
            <div
              v-if="
                limbah_padat.limbah_padat[
                  'apakah_dilakukan_pemilahan_limbah_padat?'
                ].dataPenyusun_desc == 2
              "
            >
              <v-card
                outlined
                class="pa-3 mb-4 rounded-max"
              >
                <v-row>
                  <v-col cols="3">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Jumlah Total Limbah
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="7">
                    <v-card
                      class="d-flex align-center pl-2 px-5"
                      outlined
                      style="width: max-content"
                    >
                      <input
                        v-model="
                          limbah_padat.limbah_padat['jumlah_total_limbah_padat']
                            .dataPenyusun_desc
                        "
                        type="number"
                        disabled
                        class="pa-2 mr-1 custom_input"
                      >
                      <div class="icon-group">
                        kg/bulan
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <!-- TAB 2 -->

            <v-card
              color="#CFEEF2"
              flat
              class="mb-5"
            >
              <div class="fill_header white--text py-2 px-5">
                <b>2. Sumber Daya dan Organisasi</b>
              </div>
            </v-card>
            <v-card
              class="rounded-max py-3 px-5 mb-5"
              outlined
            >
              <div class="mb-3">
                <b>Unit Khusus / Organisasi Kesehatan Lingkungan</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="9">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat unit yang bertanggung jawab terhadap kesehatan lingkungan ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="
                        sumber_daya
                          .unit_khusus___organisasi_kesehatan_lingkungan[
                            'apakah_terdapat_unit_khusus_kesehatan_lingkungan?'
                          ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Petugas Kesehatan Lingkungan</b>
              </div>
              <v-row>
                <v-col cols="5">
                  <div
                    class="d-flex align-center"
                    style="height: 100%"
                  >
                    <p class="ma-0 small_txt color_txt">
                      Berapa jumlah petugas kesehatan lingkungan ?
                    </p>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      v-model="
                        sumber_daya.petugas_kesehatan_lingkungan[
                          'jumlah_petugas_kesehatan_lingkungan'
                        ].dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 mr-1 custom_input"
                    >
                    <p class="ma-0 small_txt color_txt">
                      orang
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Petugas Pengelola Limbah B3</b>
              </div>
              <v-row>
                <v-col cols="5">
                  <div
                    class="d-flex align-center"
                    style="height: 100%"
                  >
                    <p class="ma-0 small_txt color_txt">
                      Berapa jumlah petugas pengelola limbah ?
                    </p>
                  </div>
                </v-col>
                <v-col cols="5">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      v-model="
                        sumber_daya.petugas_pengelola_limbah_b3[
                          'jumlah_petugas_pengelola_limbah'
                        ].dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 mr-1 custom_input"
                    >
                    <p class="ma-0 small_txt color_txt">
                      orang
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Alokasi Dana / Anggaran (Tahunan)</b>
              </div>
              <v-row class="mb-4">
                <v-col cols="5">
                  <div
                    class="d-flex align-center"
                    style="height: 100%"
                  >
                    <p class="ma-0 small_txt color_txt">
                      Berapa jumlah dana atau anggaran pengelolaan limbah dalam setahun
                    </p>
                  </div>
                </v-col>
                <v-col cols="5">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <p class="ma-0 small_txt color_txt mr-2">
                      Rp
                    </p>
                    <input
                      v-model="
                        sumber_daya.alokasi_dana___anggaran[
                          'jumlah_dana___anggaran_kelola_limbah'
                        ].dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 custom_input"
                    >
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="mb-4">
                <v-col cols="5">
                  <div
                    class="d-flex align-center"
                    style="height: 100%"
                  >
                    <p class="ma-0 small_txt color_txt">
                      Berapa jumlah dana atau anggaran kesehatan lingkungan dalam setahun
                    </p>
                  </div>
                </v-col>
                <v-col cols="5">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <p class="ma-0 small_txt mr-2 color_txt">
                      Rp
                    </p>
                    <input
                      v-model="
                        sumber_daya.alokasi_dana___anggaran[
                          'jumlah_dana___anggaran_kesehatan_lingkungan'
                        ].dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 custom_input"
                    >
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <div
                    class="d-flex align-center"
                    style="height: 100%"
                  >
                    <p class="ma-0 small_txt color_txt">
                      Berapa jumlah dana atau anggaran keseluruhan Fasyankes dalam setahun
                    </p>
                  </div>
                </v-col>
                <v-col cols="5">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <p class="ma-0 small_txt mr-2 color_txt">
                      Rp
                    </p>
                    <input
                      v-model="
                        sumber_daya.alokasi_dana___anggaran[
                          'jumlah_dana___anggaran_keseluruhan_fasyankes'
                        ].dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 custom_input"
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Dokumen Izin Lingkungan</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="9">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat dokumen izin lingkungan?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="
                        sumber_daya.dokumen_izin_lingkungan[
                          'apakah_terdapat_dokumen_izin_lingkungan?'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="mb-4">
                <v-col cols="3">
                  <div class="d-flex align-center">
                    <v-card
                      class="d-flex align-center pl-2 px-5 mr-2"
                      outlined
                      style="width: max-content"
                    >
                      <p class="ma-0 small_txt mr-2 color_txt">
                        Tahun
                      </p>
                      <input
                        v-model="
                          sumber_daya.dokumen_izin_lingkungan[
                            'tahun_izin_dokumen_lingkungan'
                          ].dataPenyusun_desc
                        "
                        type="number"
                        disabled
                        class="pa-2 custom_input"
                      >
                    </v-card>
                    <v-icon>mdi-minus</v-icon>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-card
                    class="d-flex align-center pl-2 px-5 mx-3"
                    outlined
                    style="width: max-content"
                  >
                    <p class="ma-0 small_txt mr-2 color_txt">
                      No
                    </p>
                    <input
                      v-model="
                        sumber_daya.dokumen_izin_lingkungan[
                          'nomor_izin_dokumen_lingkungan'
                        ].dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 custom_input"
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Kebijakan Tertulis (SE/SK) Mengenai Pengelolaan Limbah
                  Medis/B3</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="9">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat kebijakan tertulis (SE/SK) mengenai pengelolaan limbah Fasyankes ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="
                        sumber_daya
                          .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                            'apakah_terdapat_kebijakan_tertulis_mengenai_pengelolaan_limbah_medis_b3_'
                          ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="mb-4">
                <v-col cols="3">
                  <div class="d-flex align-center">
                    <v-card
                      class="d-flex align-center pl-2 px-5 mr-2"
                      outlined
                      style="width: max-content"
                    >
                      <p class="ma-0 small_txt mr-2 color_txt">
                        Tahun
                      </p>
                      <input
                        v-model="
                          sumber_daya
                            .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                              'tahun_kebijakan_dokumen_lingkungan'
                            ].dataPenyusun_desc
                        "
                        type="number"
                        disabled
                        class="pa-2 custom_input"
                      >
                    </v-card>
                    <v-icon>mdi-minus</v-icon>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-card
                    class="d-flex align-center pl-2 px-5 mx-3"
                    outlined
                    style="width: max-content"
                  >
                    <p class="ma-0 small_txt mr-2 color_txt">
                      No
                    </p>
                    <input
                      v-model="
                        sumber_daya
                          .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                            'nomor_kebijakan_dokumen_lingkungan'
                          ].dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 custom_input"
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Prosedur Pengelolaan Limbah (SOP)</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="9">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat prosedur pengelolaan limbah (SOP) ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="
                        sumber_daya.prosedur_pengelolaan_limbah__sop_[
                          'apakah_terdapat_prosedur_pengelolaan_limbah__sop___'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="mb-4">
                <v-col cols="3">
                  <div class="d-flex align-center">
                    <v-card
                      class="d-flex align-center pl-2 px-5 mr-2"
                      outlined
                      style="width: max-content"
                    >
                      <p class="ma-0 small_txt mr-2 color_txt">
                        Tahun
                      </p>
                      <input
                        v-model="
                          sumber_daya.prosedur_pengelolaan_limbah__sop_[
                            'tahun_sop'
                          ].dataPenyusun_desc
                        "
                        type="number"
                        disabled
                        class="pa-2 custom_input"
                      >
                    </v-card>
                    <v-icon>mdi-minus</v-icon>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-card
                    class="d-flex align-center pl-2 px-5 mx-3"
                    outlined
                    style="width: max-content"
                  >
                    <p class="ma-0 small_txt mr-2 color_txt">
                      No
                    </p>
                    <input
                      v-model="
                        sumber_daya.prosedur_pengelolaan_limbah__sop_[
                          'nomor_sop'
                        ].dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 custom_input"
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Penyediaan Wadah</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="7">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah kuantitas wadah memadai ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="5">
                    <v-radio-group
                      v-model="
                        sumber_daya.penyediaan_wadah['kualitas_wadah']
                          .dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <div class="d-flex">
                <div
                  class="blue_circle d-flex justify-center align-center mr-2"
                >
                  <v-icon
                    dark
                    small
                  >
                    mdi-information-variant
                  </v-icon>
                </div>
                <b class="mr-5 small_txt">Ya</b>
                <p class="ma-0 small_txt color_txt">
                  : Wadah limbah tersedia di semua tempat penghasil limbah dengan jumlah dan kapasitas wadah yang memadai
                </p>
              </div>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Penyediaan Alat Angkut</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="7">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah kuantitas alat angkut memadai ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="5">
                    <v-radio-group
                      v-model="
                        sumber_daya.penyediaan_alat_angkut[
                          'kualitas_alat_angkut'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <div class="d-flex">
                <div
                  class="blue_circle d-flex justify-center align-center mr-2"
                >
                  <v-icon
                    dark
                    small
                  >
                    mdi-information-variant
                  </v-icon>
                </div>
                <b class="mr-5 small_txt">Ya</b>
                <p class="ma-0 small_txt color_txt">
                  : Alat angkut limbah tersedia dengan jumlah dan kapasitas yang memadai untuk mengumpulkan limbah dari sumber ke penyimpanan
                </p>
              </div>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Penyediaan Alat Pelindung Diri (APD)</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="7">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah kuantitas Alat Pelindung Diri (APD) memadai ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="5">
                    <v-radio-group
                      v-model="
                        sumber_daya.penyediaan_alat_pelindung_diri__apd_[
                          'kualitas_apd'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <div class="d-flex">
                <div
                  class="blue_circle d-flex justify-center align-center mr-2"
                >
                  <v-icon
                    dark
                    small
                  >
                    mdi-information-variant
                  </v-icon>
                </div>
                <b class="mr-5 small_txt">Ya</b>
                <p class="ma-0 small_txt color_txt">
                  : APD tersedia dengan jenis lengkap serta jumlah dan ukuran yang memadai untuk digunakan petugas
                </p>
              </div>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Timbangan Medis</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="5">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat timbangan limbah medis yang dapat digunakan ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="7">
                    <v-radio-group
                      v-model="
                        sumber_daya.timbangan_medis[
                          'ketersediaan_dan_status_timbangan_limbah_medis'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <!-- <v-radio
                        color="#00ccb6"
                        label="Ada - Tidak berfungsi"
                        value="2"
                      ></v-radio> -->
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="3"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Pencatatan Limbah Medis (Log Book)</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="9">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat pencatatan timbulan limbah medis (log book) ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="
                        sumber_daya.pencatatan_limbah_medis__log_book_[
                          'apakah_terdapat_pencatatan_limbah_medis__log_book_'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Cold storage / Freezer Khusus Limbah Medis</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="5">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat cold storage/freezer limbah infeksius yang dapat digunakan ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="7">
                    <v-radio-group
                      v-model="
                        sumber_daya
                          .cold_storage___freezer_khusus_limbah_medis[
                            'ketersediaan_dan_status_cold_storage___freezer_khusus_limbah_medis'
                          ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="2"
                      />
                      <!-- <v-radio
                        color="#00ccb6"
                        label="Ada - Tidak berfungsi"
                        value="3"
                      ></v-radio> -->
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="0"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Kapasitas Cold Storage / Freezer</b>
              </div>
              <v-row>
                <v-col cols="5">
                  <div
                    class="d-flex align-center"
                    style="height: 100%"
                  >
                    <p class="ma-0 small_txt color_txt">
                      Jumlah Kapasitas cold storage / freezer
                    </p>
                  </div>
                </v-col>
                <v-col cols="5">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      v-model="
                        sumber_daya.kapasitas_cold_storage___freezer[
                          'jumlah_kapasitas_cold_storage___freezer'
                        ].dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 mr-1 custom_input"
                    >
                    <p class="ma-0 small_txt color_txt">
                      m3
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <!-- TAB 3 -->

            <v-card
              color="#CFEEF2"
              flat
              class="mb-5"
            >
              <div class="fill_header white--text py-2 px-5">
                <b>3. Alur Pengelolaan Limbah Padat</b>
              </div>
            </v-card>
            <v-card
              class="rounded-max py-3 px-5 mb-5"
              outlined
            >
              <div class="mb-3">
                <b>Pewadahan</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah tersedia plastik warna hitam untuk limbah domestik ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.jenis_pewadahan_limbah_domestik[
                          'ketersediaan_plastik_hitam_untuk_limbah_domestik'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ada"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak ada"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah tersedia plastik warna kuning untuk limbah medis infeksius dan patologi ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.jenis_pewadahan_limbah_medis_infeksius[
                          'ketersediaan_plastik_kuning_untuk_limbah_medis_infeksius_dan_patologi'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ada"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak ada"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah tersedia safety box untuk limbah tajam ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.jenis_pewadahan_limbah_medis_infeksius[
                          'ketersediaan_safety_box_untuk_limbah_tajam'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ada"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak ada"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah tersedia plastik warna ungu untuk limbah sitotoksik ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.jenis_pewadahan_limbah_medis_non_infeksius[
                          'ketersediaan_plastik_warna_ungu_untuk_sitotoksis'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ada"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak ada"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah tersedia plastik warna merah untuk limbah radioaktif ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.jenis_pewadahan_limbah_medis_non_infeksius[
                          'ketersediaan_plastik_warna_merah_untuk_radioaktif'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ada"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak ada"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah tersedia plastik warna cokelat untuk limbah farmasi ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.jenis_pewadahan_limbah_medis_non_infeksius[
                          'ketersediaan_plastik_warna_coklat_untuk_farmasi'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ada"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak ada"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah tersedia plastik warna cokelat untuk limbah kimia ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.jenis_pewadahan_limbah_medis_non_infeksius[
                          'ketersediaan_plastik_warna_coklat_untuk_kimia'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ada"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak ada"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>

            <v-card
              class="rounded-max py-3 px-5 mb-5"
              outlined
            >
              <div class="mb-3">
                <b>Apakah pewadahan limbah medis memiliki kriteria sebagai berikut :</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Tertutup
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pewadahan_plastik['tertutup']
                          .dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Tidak bocor
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pewadahan_plastik['tidak_bocor']
                          .dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Label sesuai
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pewadahan_plastik['label_sesuai']
                          .dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Simbol sesuai
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pewadahan_plastik['simbol_sesuai']
                          .dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Mudah dibersihkan
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pewadahan_plastik['mudah_dibersihkan']
                          .dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Mudah dibersihkan
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pewadahan_plastik['warna_sesuai']
                          .dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>

            <v-card
              class="rounded-max py-3 px-5 mb-5"
              outlined
            >
              <div class="mb-3">
                <b>Apakah pengangkutan/pengumpulan limbah medis memiliki kriteria sebagai berikut :</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Khusus Alat angkut khusus limbah
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                          'khusus_alat_angkut_khusus_limbah'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Wheel bin/wadah beroda
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                          'wheel_bin_wadah_beroda'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Jalur khusus
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                          'jalur_khusus'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Waktu khusus
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                          'waktu_khusus'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Tidak bocor
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                          'tidak_bocor'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Label sesuai
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                          'label_sesuai'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Simbol sesuai
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                          'simbol_sesuai'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Mudah dibersihkan
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                          'mudah_dibersihkan'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Warna sesuai
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                          'warna_sesuai'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>

            <v-card
              class="rounded-max py-3 px-5 mb-5"
              outlined
            >
              <div class="mb-3">
                <b>Dimana penyimpanan limbah medis dilakukan ?</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Di Tempat Penyimpanan Sementara (TPS) limbah B3 berizin (di dalam area Fasyankes)
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.ketersediaan_ruang_penyimpanan[
                          'di_tempat_penyimpanan_sementara__tps__limbah_b3_berizin__dalam_area_fasyankes_'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Di Tempat Penyimpanan Sementara (TPS) Limbah B3 depo pemindahan berizin (di luar area Fasyankes)
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.ketersediaan_ruang_penyimpanan[
                          'di_tempat_penyimpanan_sementara__tps__limbah_b3_depo_pemindahan_berizin__diluar_area_fasyankes_'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Di tempat lainnya (gudang, ruang kosong, ruang terbuka, dll)
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.ketersediaan_ruang_penyimpanan[
                          'lainnya__di_gudang,_ruang_kosong,_ruang_terbuka,_dll_'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Tidak ada tempat penyimpanan
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.ketersediaan_ruang_penyimpanan[
                          'tidak_ada_penyimpanan'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>

            <v-card
              class="rounded-max py-3 px-5 mb-5"
              outlined
            >
              <div class="mb-3">
                <b>Pengangkutan</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah tersedia kendaraan pengangkut limbah roda tiga ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur
                          .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                            'jumlah_kendaraan_roda_tiga'
                          ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah tersedia kendaraan pengangkut limbah roda empat ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur
                          .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                            'jumlah_kendaraan_roda_empat'
                          ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah tersedia kendaraan pengangkut limbah dari perusahaan pengelola limbah ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur
                          .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                            'jumlah_kendaraan_perusahaan_pengangkut'
                          ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Ketersediaan Alat Pengolah Limbah Di Fasyankes</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat insinerator yang berfungsi sebagai alat pengolah limbah ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.ketersediaan_alat_pengolah_limbah[
                          'apakah_terdapat_pengolah_limbah_insinerator'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="mb-3">
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <label
                      class="label-tipe-2"
                      for="jumlahPetugasPengelolaLimbah"
                    >
                      Kapasitas insinerator :
                    </label>
                    <input
                      v-model="
                        alur.ketersediaan_alat_pengolah_limbah[
                          'kapasitas_operasinal_insinerator'
                        ].dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 mr-1 custom_input"
                    >
                    <p class="ma-0 small_txt color_txt">
                      Kg/jam
                    </p>
                  </v-card>
                </v-col>
              </v-row>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat autoclave/microwave yang berfungsi sebagai alat pengolah limbah ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        alur.ketersediaan_alat_pengolah_limbah[
                          'apakah_terdapat_pengolah_limbah_autoclave_microwave'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-row>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <label
                      class="label-tipe-2"
                      for="jumlahPetugasPengelolaLimbah"
                    >
                      Kapasitas autoclave/microwave :
                    </label>
                    <input
                      v-model="
                        alur.ketersediaan_alat_pengolah_limbah[
                          'kapasitas_operasinal_autoclave_microwave'
                        ].dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 mr-1 custom_input"
                    >
                    <p class="ma-0 small_txt color_txt">
                      Kg/jam
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Spesifikasi pengolahan limbah</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="9">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat insinerator berizin sebagai alat pengolah limbah ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pengolahan_limbah[
                          'diolah_dengan_insinerator'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="mb-4">
                <v-col cols="4">
                  <div class="d-flex align-center">
                    <v-card
                      class="d-flex align-center pl-2 px-5 mr-2"
                      outlined
                      style="width: max-content"
                    >
                      <p class="ma-0 small_txt mr-2 color_txt">
                        Tanggal Izin
                      </p>
                      <input
                        v-model="
                          alur.spesifikasi_pengolahan_limbah[
                            'tangggal_izin_insinerator'
                          ].dataPenyusun_desc
                        "
                        placeholder="yyyy/mm/dd"
                        disabled
                        class="pa-2 custom_input"
                      >
                    </v-card>
                    <v-icon>mdi-minus</v-icon>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-card
                    class="d-flex align-center pl-2 px-5 mr-2"
                    outlined
                    style="width: max-content"
                  >
                    <p class="ma-0 small_txt mr-2 color_txt">
                      No
                    </p>
                    <input
                      v-model="
                        alur.spesifikasi_pengolahan_limbah['no_insinerator']
                          .dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 custom_input"
                    >
                  </v-card>
                </v-col>
              </v-row>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="9">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat autoclave/microwave berizin sebagai alat pengolah limbah ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pengolahan_limbah[
                          'apakah_terdapat_alat_pengolah_limbah_autoclave_microwave'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="mb-4">
                <v-col cols="4">
                  <div class="d-flex align-center">
                    <v-card
                      class="d-flex align-center pl-2 px-5 mr-2"
                      outlined
                      style="width: max-content"
                    >
                      <p class="ma-0 small_txt mr-2 color_txt">
                        Tanggal Izin
                      </p>
                      <input
                        v-model="
                          alur.spesifikasi_pengolahan_limbah[
                            'tangggal_izin_autoclave_microwave'
                          ].dataPenyusun_desc
                        "
                        placeholder="yyyy/mm/dd"
                        class="pa-2 custom_input"
                      >
                    </v-card>
                    <v-icon>mdi-minus</v-icon>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-card
                    class="d-flex align-center pl-2 px-5 mx-3"
                    outlined
                    style="width: max-content"
                  >
                    <p class="ma-0 small_txt mr-2 color_txt">
                      No
                    </p>
                    <input
                      v-model="
                        alur.spesifikasi_pengolahan_limbah[
                          'no_autoclave_microwave'
                        ].dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 custom_input"
                    > 
                  </v-card>
                </v-col>
              </v-row>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="9">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah Fasyankes melakukan kerja sama dengan pengolah limbah medis berizin ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pengolahan_limbah[
                          'bekerja_sama_dengan_pengolah_limbah_medis'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="mb-4">
                <v-col cols="4">
                  <div class="d-flex align-center">
                    <v-card
                      class="d-flex align-center pl-2 px-5 mr-2"
                      outlined
                      style="width: max-content"
                    >
                      <p class="ma-0 small_txt mr-2 color_txt">
                        Tanggal PKS
                      </p>
                      <input
                        v-model="
                          alur.spesifikasi_pengolahan_limbah['tanggal_pks']
                            .dataPenyusun_desc
                        "
                        placeholder="yyyy/mm/dd"
                        disabled
                        class="pa-2 custom_input"
                      >
                    </v-card>
                    <v-icon>mdi-minus</v-icon>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-card
                    class="d-flex align-center pl-2 px-5 mr-2"
                    outlined
                    style="width: max-content"
                  >
                    <p class="ma-0 small_txt mr-2 color_txt">
                      No
                    </p>
                    <input
                      v-model="
                        alur.spesifikasi_pengolahan_limbah['no_pks']
                          .dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 custom_input"
                    >
                  </v-card>
                </v-col>
              </v-row>
              <v-card
                flat
                class="pa-2 rounded-lg"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="9">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah limbah medis diolah dengan metode lainnya ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="
                        alur.spesifikasi_pengolahan_limbah[
                          'diolah_dengan_metode_lainnya'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>

            <v-card
              v-if="
                alur.spesifikasi_pengolahan_limbah[
                  'diolah_dengan_metode_lainnya'
                ].dataPenyusun_desc == 1
              "
              class="rounded-max py-3 px-5 mb-5"
              outlined
            >
              <div class="mb-3">
                <b>Pengolahan limbah metode lainnya</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="9">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Disinfeksi untuk limbah infeksius padat
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="
                        alur.pengolahan_limbah_metode_lainnya[
                          'disinfeksi_untuk_limbah_infeksius_padat'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="9">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Inertifikasi / solidifikasi untuk limbah farmasi dan
                        kimia padat
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="
                        alur.pengolahan_limbah_metode_lainnya[
                          'inertifikasi___solidifikasi_untuk_limbah_farmasi_dan_kimia_padat'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="9">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Enkapsulasi untuk limbah tajam
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="
                        alur.pengolahan_limbah_metode_lainnya[
                          'enkapsulasi_untuk_limbah_tajam'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                class="pa-2 rounded-lg"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="9">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Penguburan limbah patologi
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-radio-group
                      v-model="
                        alur.pengolahan_limbah_metode_lainnya[
                          'penguburan_limbah_patologi'
                        ].dataPenyusun_desc
                      "
                      row
                      dense
                      hide-details
                      class="ma-0"
                      disabled
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>

            <!-- TAB 4 -->
            <v-card
              color="#CFEEF2"
              flat
              class="mb-5"
            >
              <div class="fill_header white--text py-2 px-5">
                <b>4. Pengelolaan Limbah Cair</b>
              </div>
            </v-card>
            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Debit</b>
              </div>
              <v-row class="mb-4">
                <v-col cols="5">
                  <div
                    class="d-flex align-center"
                    style="height: 100%"
                  >
                    <p class="ma-0 small_txt color_txt">
                      Apakah terdapat Instalasi pengelolaan air limbah (IPAL)
                    </p>
                  </div>
                </v-col>
                <v-col cols="5">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      v-model="
                        limbah_cair.debit['rata_rata_debit_air_masuk_ipal']
                          .dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 mr-1 custom_input"
                    >
                    <p class="ma-0 small_txt color_txt">
                      Liter/hari
                    </p>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <div
                    class="d-flex align-center"
                    style="height: 100%"
                  >
                    <p class="ma-0 small_txt color_txt">
                      Berapa rata-rata debit air keluar dari IPAL
                    </p>
                  </div>
                </v-col>
                <v-col cols="5">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      v-model="
                        limbah_cair.debit['rata_rata_debit_air_keluar_ipal']
                          .dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 mr-1 custom_input"
                    >
                    <p class="ma-0 small_txt color_txt">
                      Liter/hari
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Kualitas Air Limbah</b>
              </div>
              <v-row class="mb-4">
                <v-col cols="5">
                  <div
                    class="d-flex align-center"
                    style="height: 100%"
                  >
                    <p class="ma-0 small_txt color_txt">
                      Berapa rata-rata pH harian air limbah ?
                    </p>
                  </div>
                </v-col>
                <v-col cols="5">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      v-model="
                        limbah_cair.kualitas_air_limbah['rata_rata_ph_harian']
                          .dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 mr-1 custom_input"
                    >
                    <p class="ma-0 small_txt color_txt">
                      0-14
                    </p>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <div
                    class="d-flex align-center"
                    style="height: 100%"
                  >
                    <p class="ma-0 small_txt color_txt">
                      Berapa rata-rata suhu harian air limbah ?
                    </p>
                  </div>
                </v-col>
                <v-col cols="5">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      v-model="
                        limbah_cair.kualitas_air_limbah['rata_rata_suhu_harian']
                          .dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 mr-1 custom_input"
                    >
                    <p class="ma-0 small_txt color_txt">
                      oC
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Instalasi pengelolaan air limbah (IPAL)</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah terdapat Instalasi pengelolaan air limbah (IPAL)
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        limbah_cair.instalasi_pengelolaan_air_limbah__ipal_[
                          'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-row>
                <v-col cols="3">
                  <div
                    class="d-flex align-center"
                    style="height: 100%"
                  >
                    <p class="ma-0 small_txt color_txt">
                      Jumlah kapasitas IPAL
                    </p>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <input
                      v-model="
                        limbah_cair.instalasi_pengelolaan_air_limbah__ipal_[
                          'jumlah_kapasitas_ipal'
                        ].dataPenyusun_desc
                      "
                      type="number"
                      disabled
                      class="pa-2 mr-1 custom_input"
                    >
                    <p class="ma-0 small_txt color_txt">
                      m3
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Izin pembuangan limbah cair (IPLC)</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah Fasyankes memiliki izin pembuangan limbah cair (IPLC) atau persetujuan teknis IPAL ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        limbah_cair.izin_pembuangan_limbah_cair__iplc_[
                          'apakah_terdapat_izin_pembuangan_limbah_cair__iplc_'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="mb-4">
                <v-col cols="4">
                  <div class="d-flex align-center">
                    <v-card
                      class="d-flex align-center pl-2 px-5 mr-2"
                      outlined
                      style="width: max-content"
                    >
                      <p class="ma-0 small_txt mr-2 color_txt">
                        Tanggal Izin
                      </p>
                      <input
                        v-model="
                          limbah_cair
                            .izin_pembuangan_limbah_cair__iplc_[
                              'tanggal_izin_iplc'
                            ].dataPenyusun_desc
                        "
                        type="text"
                        disabled
                        placeholder="yyyy/mm/dd"
                        class="pa-2 custom_input"
                      >
                    </v-card>
                    <v-icon>mdi-minus</v-icon>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-card
                    class="d-flex align-center pl-2 px-5 mx-3"
                    outlined
                    style="width: max-content"
                  >
                    <p class="ma-0 small_txt mr-2 color_txt">
                      No
                    </p>
                    <input
                      v-model="
                        limbah_cair.izin_pembuangan_limbah_cair__iplc_[
                          'no_iplc'
                        ].dataPenyusun_desc
                      "
                      type="text"
                      disabled
                      class="pa-2 custom_input"
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              outlined
              class="pa-3 mb-4 rounded-max"
            >
              <div class="mb-3">
                <b>Baku mutu limbah cair</b>
              </div>
              <v-card
                flat
                class="pa-2 rounded-lg mb-4"
                color="#F6F6F6"
              >
                <v-row>
                  <v-col cols="8">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                    >
                      <p class="ma-0 small_txt color_txt">
                        Apakah hasil uji baku mutu limbah cair sesuai dengan standar ?
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="
                        limbah_cair.baku_mutu_limbah_cair[
                          'apakah_baku_mutu_limbah_cair_sesuai'
                        ].dataPenyusun_desc
                      "
                      row
                      disabled
                      dense
                      hide-details
                      class="ma-0"
                    >
                      <v-radio
                        color="#00ccb6"
                        label="Ya"
                        value="1"
                      />
                      <v-radio
                        color="#00ccb6"
                        label="Tidak"
                        value="2"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="mb-4">
                <v-col cols="4">
                  <div class="d-flex align-center">
                    <v-card
                      class="d-flex align-center pl-2 px-5 mr-2"
                      outlined
                      style="width: max-content"
                    >
                      <p class="ma-0 small_txt mr-2 color_txt">
                        Tanggal Izin
                      </p>
                      <input
                        v-model="
                          limbah_cair.baku_mutu_limbah_cair[
                            'tanggal_mutu_limbah_cair'
                          ].dataPenyusun_desc
                        "
                        type="text"
                        disabled
                        placeholder="yyyy/mm/dd"
                        class="pa-2 custom_input"
                      >
                    </v-card>
                    <v-icon>mdi-minus</v-icon>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-card
                    class="d-flex align-center pl-2 px-5 mx-3"
                    outlined
                    style="width: max-content"
                  >
                    <p class="ma-0 small_txt mr-2 color_txt">
                      No
                    </p>
                    <input
                      v-model="
                        limbah_cair.baku_mutu_limbah_cair[
                          'no_mutu_limbah_cair'
                        ].dataPenyusun_desc
                      "
                      type="text"
                      disabled
                      class="pa-2 custom_input"
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </vue-html2pdf>

        <!-- end -->
      </div>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import PrintFasyankes from "../../../Formulir/Print/printFasyankes.vue";
import profileDetilData from "../profileDetilData.vue";
export default {
  name: "IsianFormFasynkesData",
  components: { profileDetilData, VueHtml2pdf, PrintFasyankes },
  props: ["isianData", "id","profile", "created"],
  computed: {
    alur() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.alur_pengelolaan_limbah_padat;
      }
      return data;
    },
    limbah_padat() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.data_limbah_padat;
      }
      return data;
    },
    limbah_cair() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.pengelolaan_limbah_cair;
      }
      return data;
    },
    sumber_daya() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.sumber_daya_dan_organisasi;
      }
      return data;
    },
  },
  methods: {
    generatePdf() {
      this.$refs[`prt`].generateForm();
    },
  },
};
</script>

<style scoped>
.fill_header {
  background: #00b4cc !important;
  width: 40%;
}
.custom_input {
  border-radius: 8px;
  width: 150px;
}
.custom_input::placeholder {
  font-size: small;
}
.custom_input:focus {
  outline: none;
}
.blue_circle {
  background: #00b4cc;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
label {
  font-size: small !important;
}
</style>