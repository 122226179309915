<template>
    <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="7"
                cy="7.5"
                r="7"
                :fill="color"
              />
            </svg>
</template>

<script>
export default {
    name: "Dot",
    props: {
        color: {
            type: String,
            default: "#DB232D"
        }
    }
}
</script>