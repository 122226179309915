<template>
  <div class="report-status-legend__container">
    <div
      v-for="(item, index) in legends"
      :key="index"
      class="d-flex align-center legend"
    >
      <Dot :color="item.color" />
      <p>{{ item.text }}</p>
    </div>
  </div>
</template>

<script>
import Dot from '../../../icons/Dot.vue'

export default {
  name: "ReportStatusLegend",
  components: {
    Dot
  },
  data() {
    return {
      legends: [
        { color: "#64ccb7", text: "Formulir Telah Terkirim dan Terkonfirmasi" },
        { color: "#59b5cc", text: "Formulir Perlu Perbaikan" },
        { color: "#d7d7d7", text: "Formulir Telah Terkirim" },
        { color: "#f4cb08", text: "Formulir Belum Terkirim" },
        { color: "#d92b2a", text: "Formulir Tidak Terisi" },
      ]
    }
  }
};
</script>

<style scoped>
.report-status-legend__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.25rem;
}

.legend {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #1C1C1C;
}

.legend p {
  color: grey;
}

</style>