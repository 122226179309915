<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.55294 0.522627H14.4471C17.8223 0.237559 20.2624 2.67774 19.9774 6.05294V14.9471C20.2624 18.3223 17.8223 20.7624 14.4471 20.4774H5.55294C2.17774 20.7624 -0.262441 18.3223 0.0226271 14.9471V6.05294C-0.262441 2.67774 2.17774 0.237559 5.55294 0.522627ZM14.2874 8.25367C14.4699 8.04842 14.5497 7.79756 14.5497 7.5353C14.5497 7.19321 14.4128 6.86253 14.1392 6.64588C13.9225 6.47484 13.6831 6.39502 13.398 6.39502C13.0673 6.39502 12.7709 6.53186 12.5428 6.79412L8.27819 11.8569L7.33177 10.5228C7.11511 10.2263 6.78444 10.0439 6.40815 10.0439C6.16869 10.0439 5.95204 10.1123 5.75819 10.2377C5.43892 10.443 5.26787 10.7623 5.26787 11.1614C5.26787 11.4008 5.33629 11.6289 5.47312 11.8341L7.24054 14.3541C7.4686 14.6734 7.79928 14.8558 8.18697 14.8558C8.51765 14.8558 8.82552 14.719 9.05357 14.4453L14.2874 8.25367Z"
      fill="#00b4cc"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCheckBox",
};
</script>