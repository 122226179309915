<template>
  <div class="indicator-container">
    <div
      v-if="valid"
      class="indicator-status"
    >
      <IconCheckList /> Sesuai Kriteria
    </div>
    <div
      v-else
      class="indicator-status indicator-status__negative"
    >
      Tidak Sesuai Kriteria
    </div>
  </div>
</template>

<script>
import IconCheckList from "../../icons/CheckList.vue";

export default {
  name: "CriteriaStatus",
  components: { IconCheckList },
  props: {
    valid: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped>
.indicator-container {
  width: 100%;
  padding-top: 10px;
  min-height: 100px;
}

.indicator-status {
  display: flex;
  justify-content: center;
  gap: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* line-height: 20px; */
  letter-spacing: -0.2px;

  border-radius: 13px;
  /* no shadow */

  margin-top: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #31b4cc;
  background-color: rgba(49, 180, 204, 0.12);
}

.indicator-status__negative {
  color: #ff0000;
  background-color: rgba(255, 0, 0, 0.12);
}
</style>