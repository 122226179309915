<template>
  <div class="padd_content">
    <div class="d-flex pa-2 mb-3 align-center">
      <blue-circle class="mr-2" />
      <h3 class="color_default">
        Analisis
      </h3>
    </div>
  
    <v-tabs
      v-model="tab"
      background-color="#cdf0f7"
      hide-slider
      class="pb-2"
    >
      <v-tab
        style="min-width: 220px; width: 220px; max-width: 220px;"
        href="#tab1"
        class="text-capitalize mr-2 header_data"
        active-class="active_tab"
      >
        <p class="ma-0">
          Penyehatan Lingkungan
        </p>
      </v-tab>
      <v-tab
        style="min-width: 220px; width: 220px; max-width: 220px;"
        href="#tab2"
        class="text-capitalize mr-2 header_data"
        active-class="active_tab"
      >
        <p class="ma-0">
          Kesling Kunci
        </p>
      </v-tab>

      <v-tab
        style="min-width: 220px; width: 220px; max-width: 220px;"
        href="#tab3"
        class="text-capitalize mr-2 header_data"
        active-class="active_tab"
      >
        <p class="ma-0">
          Timbulan Limbah
        </p>
      </v-tab>
      <v-tab
        style="min-width: 220px; width: 220px; max-width: 220px;"
        href="#tab4"
        class="text-capitalize mr-2 header_data"
        active-class="active_tab"
      >
        <p class="ma-0">
          Kelola Limbah
        </p>
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      style="background: none"
    >
      <v-tab-item value="tab1">
        <AnalysisTabPenyehatanLingkungan />
      </v-tab-item>
      <v-tab-item value="tab2">
        <TabEnvironmentHealthKey />
        <div style="height: 100px" />
      </v-tab-item>
      <v-tab-item value="tab3">
        <TabWasteEmergence />
      </v-tab-item>
      <v-tab-item value="tab4">
        <TabWasteManagement />
      </v-tab-item>
    </v-tabs-items>
    <div class="pa-2" />
  </div>
</template>
  
  <script>
import { mapState } from "vuex";
import BlueCircle from "../../components/General/BlueCircle";
import AnalysisTabPenyehatanLingkungan from "./Tab/PenyehatanLingkungan.vue";
import TabEnvironmentHealthKey from "./Tab/EnvironmentalHealthKey/index.vue"
import TabWasteEmergence from "./Tab/WasteEmergence/index.vue"
import TabWasteManagement from "./Tab/WasteManagement/index.vue"
  
  export default {
    name: "AnalysisData",
    components: {
      BlueCircle,
      AnalysisTabPenyehatanLingkungan,
      TabEnvironmentHealthKey,
      TabWasteEmergence,
      TabWasteManagement,
    },
    data() {
      return {
        tab: "tab1",
        updateLoginStatus: false,
        loginActive: true,
      };
    },
    computed: {
      ...mapState({
        users: (state) => state.user.all_user,
        role: (state) => state.role,
      }),
    },
    methods: {
      switchStatusLogin() {
        this.updateLoginStatus = true;
      },
    },
  };
  </script>
  
  <style scoped>
  .padd_content {
    padding: 40px 50px;
  }
  /* ListUser */
  .header_data {
    color: #00b4cc !important;
    background: #fff;
    width: calc(100% / 5);
    border-radius: 13px 13px 0 0;
  }
  .active_tab {
    background: #00b4cc;
    color: #fff !important;
    box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
  }
  </style>