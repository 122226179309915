var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('profile-detil-data',{attrs:{"profile":_vm.profile}}),_c('div',{staticClass:"mt-5 mx-3"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"small_txt color_txt"},[_vm._v(" "+_vm._s(_vm.$date(_vm.created).format("MMMM YYYY"))+" ")]),_c('v-btn',{attrs:{"outlined":"","small":"","color":"#00b4cc"},on:{"click":_vm.generatePdf}},[_c('v-icon',{attrs:{"color":"#00b4cc"}},[_vm._v(" mdi-printer ")])],1)],1),_c('print-fasyankes',{ref:"prt",attrs:{"id":_vm.id,"profile":_vm.profile,"created":_vm.created}}),(_vm.limbah_padat && _vm.limbah_cair && _vm.sumber_daya && _vm.alur)?_c('div',{staticClass:"mt-5"},[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":true,"float-layout":false,"enable-download":true,"preview-modal":true,"paginate-elements-by-height":1400,"filename":"hee hee","pdf-quality":2,"manual-pagination":false,"pdf-format":"a4","pdf-orientation":"portrait","pdf-content-width":"100%"}},[_c('div',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('v-card',{staticClass:"mb-5",attrs:{"color":"#CFEEF2","flat":""}},[_c('div',{staticClass:"fill_header white--text py-2 px-5"},[_c('b',[_vm._v("1. Data Limbah Padat")])])]),_c('v-card',{staticClass:"rounded-max py-3 px-5 mb-5",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah dilakukan pemilahan limbah domestik, medis tajam, dan medis infeksius pada wadah yang berbeda ? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                    _vm.limbah_padat.limbah_padat[
                      'apakah_dilakukan_pemilahan_limbah_padat?'
                    ].dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.limbah_padat.limbah_padat[
                      'apakah_dilakukan_pemilahan_limbah_padat?'
                    ], "dataPenyusun_desc", $$v)},expression:"\n                    limbah_padat.limbah_padat[\n                      'apakah_dilakukan_pemilahan_limbah_padat?'\n                    ].dataPenyusun_desc\n                  "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),(
              _vm.limbah_padat.limbah_padat[
                'apakah_dilakukan_pemilahan_limbah_padat?'
              ].dataPenyusun_desc == 1
            )?_c('div',[_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Limbah Domestik")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah dilakukan pemilahan limbah domestik ? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                        _vm.limbah_padat.limbah_domestik[
                          'apakah_dilakukan_pemilahan_domestik'
                        ].dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.limbah_padat.limbah_domestik[
                          'apakah_dilakukan_pemilahan_domestik'
                        ], "dataPenyusun_desc", $$v)},expression:"\n                        limbah_padat.limbah_domestik[\n                          'apakah_dilakukan_pemilahan_domestik'\n                        ].dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),(
                  _vm.limbah_padat.limbah_domestik[
                    'apakah_dilakukan_pemilahan_domestik'
                  ].dataPenyusun_desc == 1
                )?_c('div',[_c('v-row',{staticClass:"mb-3"},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Timbulan limbah organik ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                          _vm.limbah_padat.limbah_domestik['limbah_organik']
                            .dataPenyusun_desc
                        ),expression:"\n                          limbah_padat.limbah_domestik['limbah_organik']\n                            .dataPenyusun_desc\n                        "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                          _vm.limbah_padat.limbah_domestik['limbah_organik']
                            .dataPenyusun_desc
                        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_padat.limbah_domestik['limbah_organik']
                            , "dataPenyusun_desc", $event.target.value)}}}),_c('div',{staticClass:"icon-group"},[_vm._v(" kg/bulan ")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Timbulan limbah anorganik ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                          _vm.limbah_padat.limbah_domestik['limbah_non_organik']
                            .dataPenyusun_desc
                        ),expression:"\n                          limbah_padat.limbah_domestik['limbah_non_organik']\n                            .dataPenyusun_desc\n                        "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                          _vm.limbah_padat.limbah_domestik['limbah_non_organik']
                            .dataPenyusun_desc
                        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_padat.limbah_domestik['limbah_non_organik']
                            , "dataPenyusun_desc", $event.target.value)}}}),_c('div',{staticClass:"icon-group"},[_vm._v(" kg/bulan ")])])],1)],1)],1):_vm._e(),(
                  _vm.limbah_padat.limbah_domestik[
                    'apakah_dilakukan_pemilahan_domestik'
                  ].dataPenyusun_desc == 2
                )?_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Timbulan total limbah domestik ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.limbah_padat.limbah_domestik[
                          'jumlah_total_limbah_domestik'
                        ].dataPenyusun_desc
                      ),expression:"\n                        limbah_padat.limbah_domestik[\n                          'jumlah_total_limbah_domestik'\n                        ].dataPenyusun_desc\n                      "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                        _vm.limbah_padat.limbah_domestik[
                          'jumlah_total_limbah_domestik'
                        ].dataPenyusun_desc
                      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_padat.limbah_domestik[
                          'jumlah_total_limbah_domestik'
                        ], "dataPenyusun_desc", $event.target.value)}}}),_c('div',{staticClass:"icon-group"},[_vm._v(" kg/bulan ")])])],1)],1):_vm._e()],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Limbah Medis")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah dilakukan pemilahan limbah medis ? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                        _vm.limbah_padat.limbah_medis[
                          'apakah_dilakukan_pemilahan_limbah_medis?'
                        ].dataPenyusun_desc
                      ),callback:function ($$v) {_vm.$set(_vm.limbah_padat.limbah_medis[
                          'apakah_dilakukan_pemilahan_limbah_medis?'
                        ], "dataPenyusun_desc", $$v)},expression:"\n                        limbah_padat.limbah_medis[\n                          'apakah_dilakukan_pemilahan_limbah_medis?'\n                        ].dataPenyusun_desc\n                      "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),(
                  _vm.limbah_padat.limbah_medis[
                    'apakah_dilakukan_pemilahan_limbah_medis?'
                  ].dataPenyusun_desc == 1
                )?_c('div',[_c('v-card',{staticClass:"pa-3 mb-3 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',{staticClass:"color_default"},[_vm._v("Limbah Infeksius")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah dilakukan pemilahan limbah medis infeksius ? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                            _vm.limbah_padat.limbah_infeksius[
                              'apakah_dilakukan_pemilahan_infeksius'
                            ].dataPenyusun_desc
                          ),callback:function ($$v) {_vm.$set(_vm.limbah_padat.limbah_infeksius[
                              'apakah_dilakukan_pemilahan_infeksius'
                            ], "dataPenyusun_desc", $$v)},expression:"\n                            limbah_padat.limbah_infeksius[\n                              'apakah_dilakukan_pemilahan_infeksius'\n                            ].dataPenyusun_desc\n                          "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),(
                      _vm.limbah_padat.limbah_infeksius[
                        'apakah_dilakukan_pemilahan_infeksius'
                      ].dataPenyusun_desc == 1
                    )?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Timbulan limbah infeksius ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.limbah_padat.limbah_infeksius['limbah_infeksius']
                                .dataPenyusun_desc
                            ),expression:"\n                              limbah_padat.limbah_infeksius['limbah_infeksius']\n                                .dataPenyusun_desc\n                            "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                              _vm.limbah_padat.limbah_infeksius['limbah_infeksius']
                                .dataPenyusun_desc
                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_padat.limbah_infeksius['limbah_infeksius']
                                , "dataPenyusun_desc", $event.target.value)}}}),_c('div',{staticClass:"icon-group"},[_vm._v(" kg/bulan ")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Timbulan limbah tajam ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.limbah_padat.limbah_infeksius['limbah_tajam']
                                .dataPenyusun_desc
                            ),expression:"\n                              limbah_padat.limbah_infeksius['limbah_tajam']\n                                .dataPenyusun_desc\n                            "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                              _vm.limbah_padat.limbah_infeksius['limbah_tajam']
                                .dataPenyusun_desc
                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_padat.limbah_infeksius['limbah_tajam']
                                , "dataPenyusun_desc", $event.target.value)}}}),_c('div',{staticClass:"icon-group"},[_vm._v(" kg/bulan ")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Timbulan limbah patologi ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.limbah_padat.limbah_infeksius['limbah_patologi']
                                .dataPenyusun_desc
                            ),expression:"\n                              limbah_padat.limbah_infeksius['limbah_patologi']\n                                .dataPenyusun_desc\n                            "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                              _vm.limbah_padat.limbah_infeksius['limbah_patologi']
                                .dataPenyusun_desc
                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_padat.limbah_infeksius['limbah_patologi']
                                , "dataPenyusun_desc", $event.target.value)}}}),_c('div',{staticClass:"icon-group"},[_vm._v(" kg/bulan ")])])],1)],1)],1):_vm._e(),(
                      _vm.limbah_padat.limbah_infeksius[
                        'apakah_dilakukan_pemilahan_infeksius'
                      ].dataPenyusun_desc == 2
                    )?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Timbulan total limbah medis infeksius ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.limbah_padat.limbah_infeksius['jumlah_total_limbah_infeksius']
                                .dataPenyusun_desc
                            ),expression:"\n                              limbah_padat.limbah_infeksius['jumlah_total_limbah_infeksius']\n                                .dataPenyusun_desc\n                            "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                              _vm.limbah_padat.limbah_infeksius['jumlah_total_limbah_infeksius']
                                .dataPenyusun_desc
                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_padat.limbah_infeksius['jumlah_total_limbah_infeksius']
                                , "dataPenyusun_desc", $event.target.value)}}}),_c('div',{staticClass:"icon-group"},[_vm._v(" kg/bulan ")])])],1)],1)],1):_vm._e()],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',{staticClass:"color_default"},[_vm._v("Limbah Non-Infeksius")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah dilakukan pemilahan limbah medis non infeksius ? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                            _vm.limbah_padat.limbah_non_infeksius[
                              'apakah_dilakukan_pemilahan_noninfeksius'
                            ].dataPenyusun_desc
                          ),callback:function ($$v) {_vm.$set(_vm.limbah_padat.limbah_non_infeksius[
                              'apakah_dilakukan_pemilahan_noninfeksius'
                            ], "dataPenyusun_desc", $$v)},expression:"\n                            limbah_padat.limbah_non_infeksius[\n                              'apakah_dilakukan_pemilahan_noninfeksius'\n                            ].dataPenyusun_desc\n                          "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),(
                      _vm.limbah_padat.limbah_non_infeksius[
                        'apakah_dilakukan_pemilahan_noninfeksius'
                      ].dataPenyusun_desc == 1
                    )?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Timbulan limbah farmasi ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.limbah_padat.limbah_non_infeksius['limbah_farmasi']
                                .dataPenyusun_desc
                            ),expression:"\n                              limbah_padat.limbah_non_infeksius['limbah_farmasi']\n                                .dataPenyusun_desc\n                            "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                              _vm.limbah_padat.limbah_non_infeksius['limbah_farmasi']
                                .dataPenyusun_desc
                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_padat.limbah_non_infeksius['limbah_farmasi']
                                , "dataPenyusun_desc", $event.target.value)}}}),_c('div',{staticClass:"icon-group"},[_vm._v(" kg/bulan ")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Timbulan limbah kimia ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.limbah_padat.limbah_non_infeksius['limbah_kimia']
                                .dataPenyusun_desc
                            ),expression:"\n                              limbah_padat.limbah_non_infeksius['limbah_kimia']\n                                .dataPenyusun_desc\n                            "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                              _vm.limbah_padat.limbah_non_infeksius['limbah_kimia']
                                .dataPenyusun_desc
                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_padat.limbah_non_infeksius['limbah_kimia']
                                , "dataPenyusun_desc", $event.target.value)}}}),_c('div',{staticClass:"icon-group"},[_vm._v(" kg/bulan ")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Timbulan limbah sitotoksik ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.limbah_padat.limbah_non_infeksius['limbah_sitotoksik']
                                .dataPenyusun_desc
                            ),expression:"\n                              limbah_padat.limbah_non_infeksius['limbah_sitotoksik']\n                                .dataPenyusun_desc\n                            "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                              _vm.limbah_padat.limbah_non_infeksius['limbah_sitotoksik']
                                .dataPenyusun_desc
                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_padat.limbah_non_infeksius['limbah_sitotoksik']
                                , "dataPenyusun_desc", $event.target.value)}}}),_c('div',{staticClass:"icon-group"},[_vm._v(" kg/bulan ")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Timbulan limbah radioaktif ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.limbah_padat.limbah_non_infeksius['limbah_radioaktif']
                                .dataPenyusun_desc
                            ),expression:"\n                              limbah_padat.limbah_non_infeksius['limbah_radioaktif']\n                                .dataPenyusun_desc\n                            "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                              _vm.limbah_padat.limbah_non_infeksius['limbah_radioaktif']
                                .dataPenyusun_desc
                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_padat.limbah_non_infeksius['limbah_radioaktif']
                                , "dataPenyusun_desc", $event.target.value)}}}),_c('div',{staticClass:"icon-group"},[_vm._v(" kg/bulan ")])])],1)],1)],1):_vm._e(),(
                      _vm.limbah_padat.limbah_non_infeksius[
                        'apakah_dilakukan_pemilahan_noninfeksius'
                      ].dataPenyusun_desc == 2
                    )?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Timbulan total limbah medis non infeksius ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                              _vm.limbah_padat.limbah_non_infeksius['jumlah_total_limbah_non_infeksius']
                                .dataPenyusun_desc
                            ),expression:"\n                              limbah_padat.limbah_non_infeksius['jumlah_total_limbah_non_infeksius']\n                                .dataPenyusun_desc\n                            "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                              _vm.limbah_padat.limbah_non_infeksius['jumlah_total_limbah_non_infeksius']
                                .dataPenyusun_desc
                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_padat.limbah_non_infeksius['jumlah_total_limbah_non_infeksius']
                                , "dataPenyusun_desc", $event.target.value)}}}),_c('div',{staticClass:"icon-group"},[_vm._v(" kg/bulan ")])])],1)],1)],1):_vm._e()],1)],1):_vm._e()],1)],1):_vm._e(),(
              _vm.limbah_padat.limbah_padat[
                'apakah_dilakukan_pemilahan_limbah_padat?'
              ].dataPenyusun_desc == 2
            )?_c('div',[_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Jumlah Total Limbah ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.limbah_padat.limbah_padat['jumlah_total_limbah_padat']
                          .dataPenyusun_desc
                      ),expression:"\n                        limbah_padat.limbah_padat['jumlah_total_limbah_padat']\n                          .dataPenyusun_desc\n                      "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                        _vm.limbah_padat.limbah_padat['jumlah_total_limbah_padat']
                          .dataPenyusun_desc
                      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_padat.limbah_padat['jumlah_total_limbah_padat']
                          , "dataPenyusun_desc", $event.target.value)}}}),_c('div',{staticClass:"icon-group"},[_vm._v(" kg/bulan ")])])],1)],1)],1)],1):_vm._e(),_c('v-card',{staticClass:"mb-5",attrs:{"color":"#CFEEF2","flat":""}},[_c('div',{staticClass:"fill_header white--text py-2 px-5"},[_c('b',[_vm._v("2. Sumber Daya dan Organisasi")])])]),_c('v-card',{staticClass:"rounded-max py-3 px-5 mb-5",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Unit Khusus / Organisasi Kesehatan Lingkungan")])]),_c('v-card',{staticClass:"pa-2 rounded-lg",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat unit yang bertanggung jawab terhadap kesehatan lingkungan ? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.sumber_daya
                        .unit_khusus___organisasi_kesehatan_lingkungan[
                          'apakah_terdapat_unit_khusus_kesehatan_lingkungan?'
                        ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.sumber_daya
                        .unit_khusus___organisasi_kesehatan_lingkungan[
                          'apakah_terdapat_unit_khusus_kesehatan_lingkungan?'
                        ], "dataPenyusun_desc", $$v)},expression:"\n                      sumber_daya\n                        .unit_khusus___organisasi_kesehatan_lingkungan[\n                          'apakah_terdapat_unit_khusus_kesehatan_lingkungan?'\n                        ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Petugas Kesehatan Lingkungan")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Berapa jumlah petugas kesehatan lingkungan ? ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.sumber_daya.petugas_kesehatan_lingkungan[
                        'jumlah_petugas_kesehatan_lingkungan'
                      ].dataPenyusun_desc
                    ),expression:"\n                      sumber_daya.petugas_kesehatan_lingkungan[\n                        'jumlah_petugas_kesehatan_lingkungan'\n                      ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.sumber_daya.petugas_kesehatan_lingkungan[
                        'jumlah_petugas_kesehatan_lingkungan'
                      ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sumber_daya.petugas_kesehatan_lingkungan[
                        'jumlah_petugas_kesehatan_lingkungan'
                      ], "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" orang ")])])],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Petugas Pengelola Limbah B3")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Berapa jumlah petugas pengelola limbah ? ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.sumber_daya.petugas_pengelola_limbah_b3[
                        'jumlah_petugas_pengelola_limbah'
                      ].dataPenyusun_desc
                    ),expression:"\n                      sumber_daya.petugas_pengelola_limbah_b3[\n                        'jumlah_petugas_pengelola_limbah'\n                      ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.sumber_daya.petugas_pengelola_limbah_b3[
                        'jumlah_petugas_pengelola_limbah'
                      ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sumber_daya.petugas_pengelola_limbah_b3[
                        'jumlah_petugas_pengelola_limbah'
                      ], "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" orang ")])])],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Alokasi Dana / Anggaran (Tahunan)")])]),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Berapa jumlah dana atau anggaran pengelolaan limbah dalam setahun ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt color_txt mr-2"},[_vm._v(" Rp ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.sumber_daya.alokasi_dana___anggaran[
                        'jumlah_dana___anggaran_kelola_limbah'
                      ].dataPenyusun_desc
                    ),expression:"\n                      sumber_daya.alokasi_dana___anggaran[\n                        'jumlah_dana___anggaran_kelola_limbah'\n                      ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.sumber_daya.alokasi_dana___anggaran[
                        'jumlah_dana___anggaran_kelola_limbah'
                      ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sumber_daya.alokasi_dana___anggaran[
                        'jumlah_dana___anggaran_kelola_limbah'
                      ], "dataPenyusun_desc", $event.target.value)}}})])],1)],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Berapa jumlah dana atau anggaran kesehatan lingkungan dalam setahun ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" Rp ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.sumber_daya.alokasi_dana___anggaran[
                        'jumlah_dana___anggaran_kesehatan_lingkungan'
                      ].dataPenyusun_desc
                    ),expression:"\n                      sumber_daya.alokasi_dana___anggaran[\n                        'jumlah_dana___anggaran_kesehatan_lingkungan'\n                      ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.sumber_daya.alokasi_dana___anggaran[
                        'jumlah_dana___anggaran_kesehatan_lingkungan'
                      ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sumber_daya.alokasi_dana___anggaran[
                        'jumlah_dana___anggaran_kesehatan_lingkungan'
                      ], "dataPenyusun_desc", $event.target.value)}}})])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Berapa jumlah dana atau anggaran keseluruhan Fasyankes dalam setahun ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" Rp ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.sumber_daya.alokasi_dana___anggaran[
                        'jumlah_dana___anggaran_keseluruhan_fasyankes'
                      ].dataPenyusun_desc
                    ),expression:"\n                      sumber_daya.alokasi_dana___anggaran[\n                        'jumlah_dana___anggaran_keseluruhan_fasyankes'\n                      ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.sumber_daya.alokasi_dana___anggaran[
                        'jumlah_dana___anggaran_keseluruhan_fasyankes'
                      ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sumber_daya.alokasi_dana___anggaran[
                        'jumlah_dana___anggaran_keseluruhan_fasyankes'
                      ], "dataPenyusun_desc", $event.target.value)}}})])],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Dokumen Izin Lingkungan")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat dokumen izin lingkungan? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.sumber_daya.dokumen_izin_lingkungan[
                        'apakah_terdapat_dokumen_izin_lingkungan?'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.sumber_daya.dokumen_izin_lingkungan[
                        'apakah_terdapat_dokumen_izin_lingkungan?'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      sumber_daya.dokumen_izin_lingkungan[\n                        'apakah_terdapat_dokumen_izin_lingkungan?'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mr-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" Tahun ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.sumber_daya.dokumen_izin_lingkungan[
                          'tahun_izin_dokumen_lingkungan'
                        ].dataPenyusun_desc
                      ),expression:"\n                        sumber_daya.dokumen_izin_lingkungan[\n                          'tahun_izin_dokumen_lingkungan'\n                        ].dataPenyusun_desc\n                      "}],staticClass:"pa-2 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                        _vm.sumber_daya.dokumen_izin_lingkungan[
                          'tahun_izin_dokumen_lingkungan'
                        ].dataPenyusun_desc
                      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sumber_daya.dokumen_izin_lingkungan[
                          'tahun_izin_dokumen_lingkungan'
                        ], "dataPenyusun_desc", $event.target.value)}}})]),_c('v-icon',[_vm._v("mdi-minus")])],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mx-3",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" No ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.sumber_daya.dokumen_izin_lingkungan[
                        'nomor_izin_dokumen_lingkungan'
                      ].dataPenyusun_desc
                    ),expression:"\n                      sumber_daya.dokumen_izin_lingkungan[\n                        'nomor_izin_dokumen_lingkungan'\n                      ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.sumber_daya.dokumen_izin_lingkungan[
                        'nomor_izin_dokumen_lingkungan'
                      ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sumber_daya.dokumen_izin_lingkungan[
                        'nomor_izin_dokumen_lingkungan'
                      ], "dataPenyusun_desc", $event.target.value)}}})])],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Kebijakan Tertulis (SE/SK) Mengenai Pengelolaan Limbah Medis/B3")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat kebijakan tertulis (SE/SK) mengenai pengelolaan limbah Fasyankes ? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.sumber_daya
                        .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                          'apakah_terdapat_kebijakan_tertulis_mengenai_pengelolaan_limbah_medis_b3_'
                        ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.sumber_daya
                        .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                          'apakah_terdapat_kebijakan_tertulis_mengenai_pengelolaan_limbah_medis_b3_'
                        ], "dataPenyusun_desc", $$v)},expression:"\n                      sumber_daya\n                        .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[\n                          'apakah_terdapat_kebijakan_tertulis_mengenai_pengelolaan_limbah_medis_b3_'\n                        ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mr-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" Tahun ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.sumber_daya
                          .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                            'tahun_kebijakan_dokumen_lingkungan'
                          ].dataPenyusun_desc
                      ),expression:"\n                        sumber_daya\n                          .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[\n                            'tahun_kebijakan_dokumen_lingkungan'\n                          ].dataPenyusun_desc\n                      "}],staticClass:"pa-2 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                        _vm.sumber_daya
                          .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                            'tahun_kebijakan_dokumen_lingkungan'
                          ].dataPenyusun_desc
                      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sumber_daya
                          .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                            'tahun_kebijakan_dokumen_lingkungan'
                          ], "dataPenyusun_desc", $event.target.value)}}})]),_c('v-icon',[_vm._v("mdi-minus")])],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mx-3",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" No ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.sumber_daya
                        .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                          'nomor_kebijakan_dokumen_lingkungan'
                        ].dataPenyusun_desc
                    ),expression:"\n                      sumber_daya\n                        .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[\n                          'nomor_kebijakan_dokumen_lingkungan'\n                        ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.sumber_daya
                        .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                          'nomor_kebijakan_dokumen_lingkungan'
                        ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sumber_daya
                        .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                          'nomor_kebijakan_dokumen_lingkungan'
                        ], "dataPenyusun_desc", $event.target.value)}}})])],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Prosedur Pengelolaan Limbah (SOP)")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat prosedur pengelolaan limbah (SOP) ? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.sumber_daya.prosedur_pengelolaan_limbah__sop_[
                        'apakah_terdapat_prosedur_pengelolaan_limbah__sop___'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.sumber_daya.prosedur_pengelolaan_limbah__sop_[
                        'apakah_terdapat_prosedur_pengelolaan_limbah__sop___'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      sumber_daya.prosedur_pengelolaan_limbah__sop_[\n                        'apakah_terdapat_prosedur_pengelolaan_limbah__sop___'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mr-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" Tahun ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.sumber_daya.prosedur_pengelolaan_limbah__sop_[
                          'tahun_sop'
                        ].dataPenyusun_desc
                      ),expression:"\n                        sumber_daya.prosedur_pengelolaan_limbah__sop_[\n                          'tahun_sop'\n                        ].dataPenyusun_desc\n                      "}],staticClass:"pa-2 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                        _vm.sumber_daya.prosedur_pengelolaan_limbah__sop_[
                          'tahun_sop'
                        ].dataPenyusun_desc
                      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sumber_daya.prosedur_pengelolaan_limbah__sop_[
                          'tahun_sop'
                        ], "dataPenyusun_desc", $event.target.value)}}})]),_c('v-icon',[_vm._v("mdi-minus")])],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mx-3",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" No ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.sumber_daya.prosedur_pengelolaan_limbah__sop_[
                        'nomor_sop'
                      ].dataPenyusun_desc
                    ),expression:"\n                      sumber_daya.prosedur_pengelolaan_limbah__sop_[\n                        'nomor_sop'\n                      ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.sumber_daya.prosedur_pengelolaan_limbah__sop_[
                        'nomor_sop'
                      ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sumber_daya.prosedur_pengelolaan_limbah__sop_[
                        'nomor_sop'
                      ], "dataPenyusun_desc", $event.target.value)}}})])],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Penyediaan Wadah")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah kuantitas wadah memadai ? ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.sumber_daya.penyediaan_wadah['kualitas_wadah']
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.sumber_daya.penyediaan_wadah['kualitas_wadah']
                        , "dataPenyusun_desc", $$v)},expression:"\n                      sumber_daya.penyediaan_wadah['kualitas_wadah']\n                        .dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"blue_circle d-flex justify-center align-center mr-2"},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-information-variant ")])],1),_c('b',{staticClass:"mr-5 small_txt"},[_vm._v("Ya")]),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" : Wadah limbah tersedia di semua tempat penghasil limbah dengan jumlah dan kapasitas wadah yang memadai ")])])],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Penyediaan Alat Angkut")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah kuantitas alat angkut memadai ? ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.sumber_daya.penyediaan_alat_angkut[
                        'kualitas_alat_angkut'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.sumber_daya.penyediaan_alat_angkut[
                        'kualitas_alat_angkut'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      sumber_daya.penyediaan_alat_angkut[\n                        'kualitas_alat_angkut'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"blue_circle d-flex justify-center align-center mr-2"},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-information-variant ")])],1),_c('b',{staticClass:"mr-5 small_txt"},[_vm._v("Ya")]),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" : Alat angkut limbah tersedia dengan jumlah dan kapasitas yang memadai untuk mengumpulkan limbah dari sumber ke penyimpanan ")])])],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Penyediaan Alat Pelindung Diri (APD)")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah kuantitas Alat Pelindung Diri (APD) memadai ? ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.sumber_daya.penyediaan_alat_pelindung_diri__apd_[
                        'kualitas_apd'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.sumber_daya.penyediaan_alat_pelindung_diri__apd_[
                        'kualitas_apd'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      sumber_daya.penyediaan_alat_pelindung_diri__apd_[\n                        'kualitas_apd'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"blue_circle d-flex justify-center align-center mr-2"},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-information-variant ")])],1),_c('b',{staticClass:"mr-5 small_txt"},[_vm._v("Ya")]),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" : APD tersedia dengan jenis lengkap serta jumlah dan ukuran yang memadai untuk digunakan petugas ")])])],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Timbangan Medis")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat timbangan limbah medis yang dapat digunakan ? ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.sumber_daya.timbangan_medis[
                        'ketersediaan_dan_status_timbangan_limbah_medis'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.sumber_daya.timbangan_medis[
                        'ketersediaan_dan_status_timbangan_limbah_medis'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      sumber_daya.timbangan_medis[\n                        'ketersediaan_dan_status_timbangan_limbah_medis'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"3"}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Pencatatan Limbah Medis (Log Book)")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat pencatatan timbulan limbah medis (log book) ? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.sumber_daya.pencatatan_limbah_medis__log_book_[
                        'apakah_terdapat_pencatatan_limbah_medis__log_book_'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.sumber_daya.pencatatan_limbah_medis__log_book_[
                        'apakah_terdapat_pencatatan_limbah_medis__log_book_'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      sumber_daya.pencatatan_limbah_medis__log_book_[\n                        'apakah_terdapat_pencatatan_limbah_medis__log_book_'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Cold storage / Freezer Khusus Limbah Medis")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat cold storage/freezer limbah infeksius yang dapat digunakan ? ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.sumber_daya
                        .cold_storage___freezer_khusus_limbah_medis[
                          'ketersediaan_dan_status_cold_storage___freezer_khusus_limbah_medis'
                        ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.sumber_daya
                        .cold_storage___freezer_khusus_limbah_medis[
                          'ketersediaan_dan_status_cold_storage___freezer_khusus_limbah_medis'
                        ], "dataPenyusun_desc", $$v)},expression:"\n                      sumber_daya\n                        .cold_storage___freezer_khusus_limbah_medis[\n                          'ketersediaan_dan_status_cold_storage___freezer_khusus_limbah_medis'\n                        ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"2"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"0"}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Kapasitas Cold Storage / Freezer")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Jumlah Kapasitas cold storage / freezer ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.sumber_daya.kapasitas_cold_storage___freezer[
                        'jumlah_kapasitas_cold_storage___freezer'
                      ].dataPenyusun_desc
                    ),expression:"\n                      sumber_daya.kapasitas_cold_storage___freezer[\n                        'jumlah_kapasitas_cold_storage___freezer'\n                      ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.sumber_daya.kapasitas_cold_storage___freezer[
                        'jumlah_kapasitas_cold_storage___freezer'
                      ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sumber_daya.kapasitas_cold_storage___freezer[
                        'jumlah_kapasitas_cold_storage___freezer'
                      ], "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" m3 ")])])],1)],1)],1),_c('v-card',{staticClass:"mb-5",attrs:{"color":"#CFEEF2","flat":""}},[_c('div',{staticClass:"fill_header white--text py-2 px-5"},[_c('b',[_vm._v("3. Alur Pengelolaan Limbah Padat")])])]),_c('v-card',{staticClass:"rounded-max py-3 px-5 mb-5",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Pewadahan")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah tersedia plastik warna hitam untuk limbah domestik ? ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.jenis_pewadahan_limbah_domestik[
                        'ketersediaan_plastik_hitam_untuk_limbah_domestik'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.jenis_pewadahan_limbah_domestik[
                        'ketersediaan_plastik_hitam_untuk_limbah_domestik'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.jenis_pewadahan_limbah_domestik[\n                        'ketersediaan_plastik_hitam_untuk_limbah_domestik'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak ada","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah tersedia plastik warna kuning untuk limbah medis infeksius dan patologi ? ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.jenis_pewadahan_limbah_medis_infeksius[
                        'ketersediaan_plastik_kuning_untuk_limbah_medis_infeksius_dan_patologi'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.jenis_pewadahan_limbah_medis_infeksius[
                        'ketersediaan_plastik_kuning_untuk_limbah_medis_infeksius_dan_patologi'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.jenis_pewadahan_limbah_medis_infeksius[\n                        'ketersediaan_plastik_kuning_untuk_limbah_medis_infeksius_dan_patologi'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak ada","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah tersedia safety box untuk limbah tajam ? ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.jenis_pewadahan_limbah_medis_infeksius[
                        'ketersediaan_safety_box_untuk_limbah_tajam'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.jenis_pewadahan_limbah_medis_infeksius[
                        'ketersediaan_safety_box_untuk_limbah_tajam'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.jenis_pewadahan_limbah_medis_infeksius[\n                        'ketersediaan_safety_box_untuk_limbah_tajam'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak ada","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah tersedia plastik warna ungu untuk limbah sitotoksik ? ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.jenis_pewadahan_limbah_medis_non_infeksius[
                        'ketersediaan_plastik_warna_ungu_untuk_sitotoksis'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.jenis_pewadahan_limbah_medis_non_infeksius[
                        'ketersediaan_plastik_warna_ungu_untuk_sitotoksis'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.jenis_pewadahan_limbah_medis_non_infeksius[\n                        'ketersediaan_plastik_warna_ungu_untuk_sitotoksis'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak ada","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah tersedia plastik warna merah untuk limbah radioaktif ? ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.jenis_pewadahan_limbah_medis_non_infeksius[
                        'ketersediaan_plastik_warna_merah_untuk_radioaktif'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.jenis_pewadahan_limbah_medis_non_infeksius[
                        'ketersediaan_plastik_warna_merah_untuk_radioaktif'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.jenis_pewadahan_limbah_medis_non_infeksius[\n                        'ketersediaan_plastik_warna_merah_untuk_radioaktif'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak ada","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah tersedia plastik warna cokelat untuk limbah farmasi ? ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.jenis_pewadahan_limbah_medis_non_infeksius[
                        'ketersediaan_plastik_warna_coklat_untuk_farmasi'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.jenis_pewadahan_limbah_medis_non_infeksius[
                        'ketersediaan_plastik_warna_coklat_untuk_farmasi'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.jenis_pewadahan_limbah_medis_non_infeksius[\n                        'ketersediaan_plastik_warna_coklat_untuk_farmasi'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak ada","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah tersedia plastik warna cokelat untuk limbah kimia ? ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.jenis_pewadahan_limbah_medis_non_infeksius[
                        'ketersediaan_plastik_warna_coklat_untuk_kimia'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.jenis_pewadahan_limbah_medis_non_infeksius[
                        'ketersediaan_plastik_warna_coklat_untuk_kimia'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.jenis_pewadahan_limbah_medis_non_infeksius[\n                        'ketersediaan_plastik_warna_coklat_untuk_kimia'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ada","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak ada","value":"2"}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"rounded-max py-3 px-5 mb-5",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Apakah pewadahan limbah medis memiliki kriteria sebagai berikut :")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Tertutup ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pewadahan_plastik['tertutup']
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pewadahan_plastik['tertutup']
                        , "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pewadahan_plastik['tertutup']\n                        .dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Tidak bocor ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pewadahan_plastik['tidak_bocor']
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pewadahan_plastik['tidak_bocor']
                        , "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pewadahan_plastik['tidak_bocor']\n                        .dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Label sesuai ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pewadahan_plastik['label_sesuai']
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pewadahan_plastik['label_sesuai']
                        , "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pewadahan_plastik['label_sesuai']\n                        .dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Simbol sesuai ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pewadahan_plastik['simbol_sesuai']
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pewadahan_plastik['simbol_sesuai']
                        , "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pewadahan_plastik['simbol_sesuai']\n                        .dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Mudah dibersihkan ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pewadahan_plastik['mudah_dibersihkan']
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pewadahan_plastik['mudah_dibersihkan']
                        , "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pewadahan_plastik['mudah_dibersihkan']\n                        .dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Mudah dibersihkan ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pewadahan_plastik['warna_sesuai']
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pewadahan_plastik['warna_sesuai']
                        , "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pewadahan_plastik['warna_sesuai']\n                        .dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"rounded-max py-3 px-5 mb-5",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Apakah pengangkutan/pengumpulan limbah medis memiliki kriteria sebagai berikut :")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Khusus Alat angkut khusus limbah ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'khusus_alat_angkut_khusus_limbah'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'khusus_alat_angkut_khusus_limbah'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[\n                        'khusus_alat_angkut_khusus_limbah'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Wheel bin/wadah beroda ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'wheel_bin_wadah_beroda'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'wheel_bin_wadah_beroda'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[\n                        'wheel_bin_wadah_beroda'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Jalur khusus ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'jalur_khusus'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'jalur_khusus'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[\n                        'jalur_khusus'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Waktu khusus ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'waktu_khusus'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'waktu_khusus'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[\n                        'waktu_khusus'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Tidak bocor ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'tidak_bocor'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'tidak_bocor'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[\n                        'tidak_bocor'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Label sesuai ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'label_sesuai'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'label_sesuai'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[\n                        'label_sesuai'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Simbol sesuai ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'simbol_sesuai'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'simbol_sesuai'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[\n                        'simbol_sesuai'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Mudah dibersihkan ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'mudah_dibersihkan'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'mudah_dibersihkan'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[\n                        'mudah_dibersihkan'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Warna sesuai ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'warna_sesuai'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                        'warna_sesuai'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[\n                        'warna_sesuai'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"rounded-max py-3 px-5 mb-5",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Dimana penyimpanan limbah medis dilakukan ?")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Di Tempat Penyimpanan Sementara (TPS) limbah B3 berizin (di dalam area Fasyankes) ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.ketersediaan_ruang_penyimpanan[
                        'di_tempat_penyimpanan_sementara__tps__limbah_b3_berizin__dalam_area_fasyankes_'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.ketersediaan_ruang_penyimpanan[
                        'di_tempat_penyimpanan_sementara__tps__limbah_b3_berizin__dalam_area_fasyankes_'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.ketersediaan_ruang_penyimpanan[\n                        'di_tempat_penyimpanan_sementara__tps__limbah_b3_berizin__dalam_area_fasyankes_'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Di Tempat Penyimpanan Sementara (TPS) Limbah B3 depo pemindahan berizin (di luar area Fasyankes) ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.ketersediaan_ruang_penyimpanan[
                        'di_tempat_penyimpanan_sementara__tps__limbah_b3_depo_pemindahan_berizin__diluar_area_fasyankes_'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.ketersediaan_ruang_penyimpanan[
                        'di_tempat_penyimpanan_sementara__tps__limbah_b3_depo_pemindahan_berizin__diluar_area_fasyankes_'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.ketersediaan_ruang_penyimpanan[\n                        'di_tempat_penyimpanan_sementara__tps__limbah_b3_depo_pemindahan_berizin__diluar_area_fasyankes_'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Di tempat lainnya (gudang, ruang kosong, ruang terbuka, dll) ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.ketersediaan_ruang_penyimpanan[
                        'lainnya__di_gudang,_ruang_kosong,_ruang_terbuka,_dll_'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.ketersediaan_ruang_penyimpanan[
                        'lainnya__di_gudang,_ruang_kosong,_ruang_terbuka,_dll_'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.ketersediaan_ruang_penyimpanan[\n                        'lainnya__di_gudang,_ruang_kosong,_ruang_terbuka,_dll_'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Tidak ada tempat penyimpanan ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.ketersediaan_ruang_penyimpanan[
                        'tidak_ada_penyimpanan'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.ketersediaan_ruang_penyimpanan[
                        'tidak_ada_penyimpanan'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.ketersediaan_ruang_penyimpanan[\n                        'tidak_ada_penyimpanan'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"rounded-max py-3 px-5 mb-5",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Pengangkutan")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah tersedia kendaraan pengangkut limbah roda tiga ? ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur
                        .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                          'jumlah_kendaraan_roda_tiga'
                        ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur
                        .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                          'jumlah_kendaraan_roda_tiga'
                        ], "dataPenyusun_desc", $$v)},expression:"\n                      alur\n                        .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[\n                          'jumlah_kendaraan_roda_tiga'\n                        ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah tersedia kendaraan pengangkut limbah roda empat ? ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur
                        .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                          'jumlah_kendaraan_roda_empat'
                        ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur
                        .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                          'jumlah_kendaraan_roda_empat'
                        ], "dataPenyusun_desc", $$v)},expression:"\n                      alur\n                        .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[\n                          'jumlah_kendaraan_roda_empat'\n                        ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah tersedia kendaraan pengangkut limbah dari perusahaan pengelola limbah ? ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur
                        .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                          'jumlah_kendaraan_perusahaan_pengangkut'
                        ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur
                        .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                          'jumlah_kendaraan_perusahaan_pengangkut'
                        ], "dataPenyusun_desc", $$v)},expression:"\n                      alur\n                        .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[\n                          'jumlah_kendaraan_perusahaan_pengangkut'\n                        ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Ketersediaan Alat Pengolah Limbah Di Fasyankes")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat insinerator yang berfungsi sebagai alat pengolah limbah ? ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.alur.ketersediaan_alat_pengolah_limbah[
                        'apakah_terdapat_pengolah_limbah_insinerator'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.ketersediaan_alat_pengolah_limbah[
                        'apakah_terdapat_pengolah_limbah_insinerator'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.ketersediaan_alat_pengolah_limbah[\n                        'apakah_terdapat_pengolah_limbah_insinerator'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mb-3"},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('label',{staticClass:"label-tipe-2",attrs:{"for":"jumlahPetugasPengelolaLimbah"}},[_vm._v(" Kapasitas insinerator : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.alur.ketersediaan_alat_pengolah_limbah[
                        'kapasitas_operasinal_insinerator'
                      ].dataPenyusun_desc
                    ),expression:"\n                      alur.ketersediaan_alat_pengolah_limbah[\n                        'kapasitas_operasinal_insinerator'\n                      ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.alur.ketersediaan_alat_pengolah_limbah[
                        'kapasitas_operasinal_insinerator'
                      ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.alur.ketersediaan_alat_pengolah_limbah[
                        'kapasitas_operasinal_insinerator'
                      ], "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Kg/jam ")])])],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat autoclave/microwave yang berfungsi sebagai alat pengolah limbah ? ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.alur.ketersediaan_alat_pengolah_limbah[
                        'apakah_terdapat_pengolah_limbah_autoclave_microwave'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.ketersediaan_alat_pengolah_limbah[
                        'apakah_terdapat_pengolah_limbah_autoclave_microwave'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.ketersediaan_alat_pengolah_limbah[\n                        'apakah_terdapat_pengolah_limbah_autoclave_microwave'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('label',{staticClass:"label-tipe-2",attrs:{"for":"jumlahPetugasPengelolaLimbah"}},[_vm._v(" Kapasitas autoclave/microwave : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.alur.ketersediaan_alat_pengolah_limbah[
                        'kapasitas_operasinal_autoclave_microwave'
                      ].dataPenyusun_desc
                    ),expression:"\n                      alur.ketersediaan_alat_pengolah_limbah[\n                        'kapasitas_operasinal_autoclave_microwave'\n                      ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.alur.ketersediaan_alat_pengolah_limbah[
                        'kapasitas_operasinal_autoclave_microwave'
                      ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.alur.ketersediaan_alat_pengolah_limbah[
                        'kapasitas_operasinal_autoclave_microwave'
                      ], "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Kg/jam ")])])],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Spesifikasi pengolahan limbah")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat insinerator berizin sebagai alat pengolah limbah ? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.alur.spesifikasi_pengolahan_limbah[
                        'diolah_dengan_insinerator'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pengolahan_limbah[
                        'diolah_dengan_insinerator'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pengolahan_limbah[\n                        'diolah_dengan_insinerator'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mr-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" Tanggal Izin ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.alur.spesifikasi_pengolahan_limbah[
                          'tangggal_izin_insinerator'
                        ].dataPenyusun_desc
                      ),expression:"\n                        alur.spesifikasi_pengolahan_limbah[\n                          'tangggal_izin_insinerator'\n                        ].dataPenyusun_desc\n                      "}],staticClass:"pa-2 custom_input",attrs:{"placeholder":"yyyy/mm/dd","disabled":""},domProps:{"value":(
                        _vm.alur.spesifikasi_pengolahan_limbah[
                          'tangggal_izin_insinerator'
                        ].dataPenyusun_desc
                      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.alur.spesifikasi_pengolahan_limbah[
                          'tangggal_izin_insinerator'
                        ], "dataPenyusun_desc", $event.target.value)}}})]),_c('v-icon',[_vm._v("mdi-minus")])],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mr-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" No ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.alur.spesifikasi_pengolahan_limbah['no_insinerator']
                        .dataPenyusun_desc
                    ),expression:"\n                      alur.spesifikasi_pengolahan_limbah['no_insinerator']\n                        .dataPenyusun_desc\n                    "}],staticClass:"pa-2 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.alur.spesifikasi_pengolahan_limbah['no_insinerator']
                        .dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.alur.spesifikasi_pengolahan_limbah['no_insinerator']
                        , "dataPenyusun_desc", $event.target.value)}}})])],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat autoclave/microwave berizin sebagai alat pengolah limbah ? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.alur.spesifikasi_pengolahan_limbah[
                        'apakah_terdapat_alat_pengolah_limbah_autoclave_microwave'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pengolahan_limbah[
                        'apakah_terdapat_alat_pengolah_limbah_autoclave_microwave'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pengolahan_limbah[\n                        'apakah_terdapat_alat_pengolah_limbah_autoclave_microwave'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mr-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" Tanggal Izin ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.alur.spesifikasi_pengolahan_limbah[
                          'tangggal_izin_autoclave_microwave'
                        ].dataPenyusun_desc
                      ),expression:"\n                        alur.spesifikasi_pengolahan_limbah[\n                          'tangggal_izin_autoclave_microwave'\n                        ].dataPenyusun_desc\n                      "}],staticClass:"pa-2 custom_input",attrs:{"placeholder":"yyyy/mm/dd"},domProps:{"value":(
                        _vm.alur.spesifikasi_pengolahan_limbah[
                          'tangggal_izin_autoclave_microwave'
                        ].dataPenyusun_desc
                      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.alur.spesifikasi_pengolahan_limbah[
                          'tangggal_izin_autoclave_microwave'
                        ], "dataPenyusun_desc", $event.target.value)}}})]),_c('v-icon',[_vm._v("mdi-minus")])],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mx-3",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" No ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.alur.spesifikasi_pengolahan_limbah[
                        'no_autoclave_microwave'
                      ].dataPenyusun_desc
                    ),expression:"\n                      alur.spesifikasi_pengolahan_limbah[\n                        'no_autoclave_microwave'\n                      ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.alur.spesifikasi_pengolahan_limbah[
                        'no_autoclave_microwave'
                      ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.alur.spesifikasi_pengolahan_limbah[
                        'no_autoclave_microwave'
                      ], "dataPenyusun_desc", $event.target.value)}}})])],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah Fasyankes melakukan kerja sama dengan pengolah limbah medis berizin ? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.alur.spesifikasi_pengolahan_limbah[
                        'bekerja_sama_dengan_pengolah_limbah_medis'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pengolahan_limbah[
                        'bekerja_sama_dengan_pengolah_limbah_medis'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pengolahan_limbah[\n                        'bekerja_sama_dengan_pengolah_limbah_medis'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mr-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" Tanggal PKS ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.alur.spesifikasi_pengolahan_limbah['tanggal_pks']
                          .dataPenyusun_desc
                      ),expression:"\n                        alur.spesifikasi_pengolahan_limbah['tanggal_pks']\n                          .dataPenyusun_desc\n                      "}],staticClass:"pa-2 custom_input",attrs:{"placeholder":"yyyy/mm/dd","disabled":""},domProps:{"value":(
                        _vm.alur.spesifikasi_pengolahan_limbah['tanggal_pks']
                          .dataPenyusun_desc
                      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.alur.spesifikasi_pengolahan_limbah['tanggal_pks']
                          , "dataPenyusun_desc", $event.target.value)}}})]),_c('v-icon',[_vm._v("mdi-minus")])],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mr-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" No ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.alur.spesifikasi_pengolahan_limbah['no_pks']
                        .dataPenyusun_desc
                    ),expression:"\n                      alur.spesifikasi_pengolahan_limbah['no_pks']\n                        .dataPenyusun_desc\n                    "}],staticClass:"pa-2 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.alur.spesifikasi_pengolahan_limbah['no_pks']
                        .dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.alur.spesifikasi_pengolahan_limbah['no_pks']
                        , "dataPenyusun_desc", $event.target.value)}}})])],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah limbah medis diolah dengan metode lainnya ? ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.alur.spesifikasi_pengolahan_limbah[
                        'diolah_dengan_metode_lainnya'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.spesifikasi_pengolahan_limbah[
                        'diolah_dengan_metode_lainnya'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.spesifikasi_pengolahan_limbah[\n                        'diolah_dengan_metode_lainnya'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1)],1),(
              _vm.alur.spesifikasi_pengolahan_limbah[
                'diolah_dengan_metode_lainnya'
              ].dataPenyusun_desc == 1
            )?_c('v-card',{staticClass:"rounded-max py-3 px-5 mb-5",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Pengolahan limbah metode lainnya")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Disinfeksi untuk limbah infeksius padat ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.pengolahan_limbah_metode_lainnya[
                        'disinfeksi_untuk_limbah_infeksius_padat'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.pengolahan_limbah_metode_lainnya[
                        'disinfeksi_untuk_limbah_infeksius_padat'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.pengolahan_limbah_metode_lainnya[\n                        'disinfeksi_untuk_limbah_infeksius_padat'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Inertifikasi / solidifikasi untuk limbah farmasi dan kimia padat ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.pengolahan_limbah_metode_lainnya[
                        'inertifikasi___solidifikasi_untuk_limbah_farmasi_dan_kimia_padat'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.pengolahan_limbah_metode_lainnya[
                        'inertifikasi___solidifikasi_untuk_limbah_farmasi_dan_kimia_padat'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.pengolahan_limbah_metode_lainnya[\n                        'inertifikasi___solidifikasi_untuk_limbah_farmasi_dan_kimia_padat'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Enkapsulasi untuk limbah tajam ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.pengolahan_limbah_metode_lainnya[
                        'enkapsulasi_untuk_limbah_tajam'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.pengolahan_limbah_metode_lainnya[
                        'enkapsulasi_untuk_limbah_tajam'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.pengolahan_limbah_metode_lainnya[\n                        'enkapsulasi_untuk_limbah_tajam'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-card',{staticClass:"pa-2 rounded-lg",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Penguburan limbah patologi ")])])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","dense":"","hide-details":"","disabled":""},model:{value:(
                      _vm.alur.pengolahan_limbah_metode_lainnya[
                        'penguburan_limbah_patologi'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.alur.pengolahan_limbah_metode_lainnya[
                        'penguburan_limbah_patologi'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      alur.pengolahan_limbah_metode_lainnya[\n                        'penguburan_limbah_patologi'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1)],1):_vm._e(),_c('v-card',{staticClass:"mb-5",attrs:{"color":"#CFEEF2","flat":""}},[_c('div',{staticClass:"fill_header white--text py-2 px-5"},[_c('b',[_vm._v("4. Pengelolaan Limbah Cair")])])]),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Debit")])]),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat Instalasi pengelolaan air limbah (IPAL) ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.limbah_cair.debit['rata_rata_debit_air_masuk_ipal']
                        .dataPenyusun_desc
                    ),expression:"\n                      limbah_cair.debit['rata_rata_debit_air_masuk_ipal']\n                        .dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.limbah_cair.debit['rata_rata_debit_air_masuk_ipal']
                        .dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_cair.debit['rata_rata_debit_air_masuk_ipal']
                        , "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Liter/hari ")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Berapa rata-rata debit air keluar dari IPAL ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.limbah_cair.debit['rata_rata_debit_air_keluar_ipal']
                        .dataPenyusun_desc
                    ),expression:"\n                      limbah_cair.debit['rata_rata_debit_air_keluar_ipal']\n                        .dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.limbah_cair.debit['rata_rata_debit_air_keluar_ipal']
                        .dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_cair.debit['rata_rata_debit_air_keluar_ipal']
                        , "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Liter/hari ")])])],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Kualitas Air Limbah")])]),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Berapa rata-rata pH harian air limbah ? ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.limbah_cair.kualitas_air_limbah['rata_rata_ph_harian']
                        .dataPenyusun_desc
                    ),expression:"\n                      limbah_cair.kualitas_air_limbah['rata_rata_ph_harian']\n                        .dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.limbah_cair.kualitas_air_limbah['rata_rata_ph_harian']
                        .dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_cair.kualitas_air_limbah['rata_rata_ph_harian']
                        , "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" 0-14 ")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Berapa rata-rata suhu harian air limbah ? ")])])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.limbah_cair.kualitas_air_limbah['rata_rata_suhu_harian']
                        .dataPenyusun_desc
                    ),expression:"\n                      limbah_cair.kualitas_air_limbah['rata_rata_suhu_harian']\n                        .dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.limbah_cair.kualitas_air_limbah['rata_rata_suhu_harian']
                        .dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_cair.kualitas_air_limbah['rata_rata_suhu_harian']
                        , "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" oC ")])])],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Instalasi pengelolaan air limbah (IPAL)")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah terdapat Instalasi pengelolaan air limbah (IPAL) ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.limbah_cair.instalasi_pengelolaan_air_limbah__ipal_[
                        'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.limbah_cair.instalasi_pengelolaan_air_limbah__ipal_[
                        'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      limbah_cair.instalasi_pengelolaan_air_limbah__ipal_[\n                        'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Jumlah kapasitas IPAL ")])])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.limbah_cair.instalasi_pengelolaan_air_limbah__ipal_[
                        'jumlah_kapasitas_ipal'
                      ].dataPenyusun_desc
                    ),expression:"\n                      limbah_cair.instalasi_pengelolaan_air_limbah__ipal_[\n                        'jumlah_kapasitas_ipal'\n                      ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 mr-1 custom_input",attrs:{"type":"number","disabled":""},domProps:{"value":(
                      _vm.limbah_cair.instalasi_pengelolaan_air_limbah__ipal_[
                        'jumlah_kapasitas_ipal'
                      ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_cair.instalasi_pengelolaan_air_limbah__ipal_[
                        'jumlah_kapasitas_ipal'
                      ], "dataPenyusun_desc", $event.target.value)}}}),_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" m3 ")])])],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Izin pembuangan limbah cair (IPLC)")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah Fasyankes memiliki izin pembuangan limbah cair (IPLC) atau persetujuan teknis IPAL ? ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.limbah_cair.izin_pembuangan_limbah_cair__iplc_[
                        'apakah_terdapat_izin_pembuangan_limbah_cair__iplc_'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.limbah_cair.izin_pembuangan_limbah_cair__iplc_[
                        'apakah_terdapat_izin_pembuangan_limbah_cair__iplc_'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      limbah_cair.izin_pembuangan_limbah_cair__iplc_[\n                        'apakah_terdapat_izin_pembuangan_limbah_cair__iplc_'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mr-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" Tanggal Izin ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.limbah_cair
                          .izin_pembuangan_limbah_cair__iplc_[
                            'tanggal_izin_iplc'
                          ].dataPenyusun_desc
                      ),expression:"\n                        limbah_cair\n                          .izin_pembuangan_limbah_cair__iplc_[\n                            'tanggal_izin_iplc'\n                          ].dataPenyusun_desc\n                      "}],staticClass:"pa-2 custom_input",attrs:{"type":"text","disabled":"","placeholder":"yyyy/mm/dd"},domProps:{"value":(
                        _vm.limbah_cair
                          .izin_pembuangan_limbah_cair__iplc_[
                            'tanggal_izin_iplc'
                          ].dataPenyusun_desc
                      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_cair
                          .izin_pembuangan_limbah_cair__iplc_[
                            'tanggal_izin_iplc'
                          ], "dataPenyusun_desc", $event.target.value)}}})]),_c('v-icon',[_vm._v("mdi-minus")])],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mx-3",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" No ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.limbah_cair.izin_pembuangan_limbah_cair__iplc_[
                        'no_iplc'
                      ].dataPenyusun_desc
                    ),expression:"\n                      limbah_cair.izin_pembuangan_limbah_cair__iplc_[\n                        'no_iplc'\n                      ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 custom_input",attrs:{"type":"text","disabled":""},domProps:{"value":(
                      _vm.limbah_cair.izin_pembuangan_limbah_cair__iplc_[
                        'no_iplc'
                      ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_cair.izin_pembuangan_limbah_cair__iplc_[
                        'no_iplc'
                      ], "dataPenyusun_desc", $event.target.value)}}})])],1)],1)],1),_c('v-card',{staticClass:"pa-3 mb-4 rounded-max",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Baku mutu limbah cair")])]),_c('v-card',{staticClass:"pa-2 rounded-lg mb-4",attrs:{"flat":"","color":"#F6F6F6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" Apakah hasil uji baku mutu limbah cair sesuai dengan standar ? ")])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio-group',{staticClass:"ma-0",attrs:{"row":"","disabled":"","dense":"","hide-details":""},model:{value:(
                      _vm.limbah_cair.baku_mutu_limbah_cair[
                        'apakah_baku_mutu_limbah_cair_sesuai'
                      ].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.limbah_cair.baku_mutu_limbah_cair[
                        'apakah_baku_mutu_limbah_cair_sesuai'
                      ], "dataPenyusun_desc", $$v)},expression:"\n                      limbah_cair.baku_mutu_limbah_cair[\n                        'apakah_baku_mutu_limbah_cair_sesuai'\n                      ].dataPenyusun_desc\n                    "}},[_c('v-radio',{attrs:{"color":"#00ccb6","label":"Ya","value":"1"}}),_c('v-radio',{attrs:{"color":"#00ccb6","label":"Tidak","value":"2"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mr-2",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" Tanggal Izin ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.limbah_cair.baku_mutu_limbah_cair[
                          'tanggal_mutu_limbah_cair'
                        ].dataPenyusun_desc
                      ),expression:"\n                        limbah_cair.baku_mutu_limbah_cair[\n                          'tanggal_mutu_limbah_cair'\n                        ].dataPenyusun_desc\n                      "}],staticClass:"pa-2 custom_input",attrs:{"type":"text","disabled":"","placeholder":"yyyy/mm/dd"},domProps:{"value":(
                        _vm.limbah_cair.baku_mutu_limbah_cair[
                          'tanggal_mutu_limbah_cair'
                        ].dataPenyusun_desc
                      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_cair.baku_mutu_limbah_cair[
                          'tanggal_mutu_limbah_cair'
                        ], "dataPenyusun_desc", $event.target.value)}}})]),_c('v-icon',[_vm._v("mdi-minus")])],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5 mx-3",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt mr-2 color_txt"},[_vm._v(" No ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.limbah_cair.baku_mutu_limbah_cair[
                        'no_mutu_limbah_cair'
                      ].dataPenyusun_desc
                    ),expression:"\n                      limbah_cair.baku_mutu_limbah_cair[\n                        'no_mutu_limbah_cair'\n                      ].dataPenyusun_desc\n                    "}],staticClass:"pa-2 custom_input",attrs:{"type":"text","disabled":""},domProps:{"value":(
                      _vm.limbah_cair.baku_mutu_limbah_cair[
                        'no_mutu_limbah_cair'
                      ].dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.limbah_cair.baku_mutu_limbah_cair[
                        'no_mutu_limbah_cair'
                      ], "dataPenyusun_desc", $event.target.value)}}})])],1)],1)],1)],1)])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }