<template>
  <div>
    <v-card class="radius_intab custom_card  pa-10">
      <v-container
        style="border: 1px solid #ccc; border-radius: 7px;"
        class="mb-8"
      >
        <div>
          <!-- Three elements in a row with borders -->
          <v-row style="padding-top: 2rem; padding-bottom: 2rem;">
            <v-col style="padding-left: 3rem; margin-right: 2rem;">
              <!-- Element 1 -->
              <div class="border-right">
                <h4>Rata-rata Timbulan Infeksius</h4>
                <div class="d-flex mt-2">
                  <h1 class="">
                    {{ waste_emergence_summary.average_medic_infectious }}
                  </h1>
                  <span class="d-flex align-self-end ml-1">
                    Kg/ Hari
                  </span>
                </div>
              </div>
            </v-col>
            <v-col style="padding-left: 3rem; margin-right: 2rem;">
              <!-- Element 2 -->
              <div class="border-right">
                <h4>Rata-rata Timbulan NonInfeksius</h4>
                <div class="d-flex  mt-2">
                  <h1 class="">
                    {{ waste_emergence_summary.average_medic_non_infectious }}
                  </h1>
                  <span class="align-self-end ml-1">
                    Kg/ Hari
                  </span>
                </div>
              </div>
            </v-col>
            <v-col class="">
              <!-- Element 3 -->
              <div>
                <h4>Rata-rata Total Timbulan Limbah</h4>
                <div class="d-flex mt-2">
                  <h1 class="">
                    {{ waste_emergence_summary.average_all }}
                  </h1>
                  <span class="d-flex align-self-end ml-1">
                    Kg/ Hari
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>

          <!-- Bold text centered under the elements -->
          <v-row class="border-top mx-6">
            <div class="d-flex mx-auto">
              <v-card-text class="font-weight-bold ">
                <h3 class="text-center">
                  Jumlah Fasyankes: {{ waste_emergence_summary.count_health_facilitator }}
                </h3>
              </v-card-text>
            </div>
          </v-row>
        </div>
      </v-container>
      <div class="toolbar d-flex">
        <div
          v-if="provinces"
          class="toolbar_items"
        >
          <span class="dropdown-label">Provinsi </span>
          <v-select
            v-if="
              role === ROLES.PublicHealthService_Regency ||
                role === ROLES.PublicHealthService_Province
            "
            v-model="selected_province_id"
            placeholder="Provinsi"
            dense
            disabled
            item-text="provinsi_name"
            item-value="provinsi_id"
            :items="provinces"
            hide-details="auto"
            outlined
            class="mt-1"
            @change="getCities"
          />
          <v-select
            v-else
            v-model="selected_province_id"
            placeholder="Provinsi"
            dense
            item-text="provinsi_name"
            item-value="provinsi_id"
            :items="provinces"
            hide-details="auto"
            outlined
            class="mt-1"
            @change="getCities"
          />
        </div>
        <div class="toolbar_items">
          <span class="dropdown-label">Kabupaten/Kota</span>
          <v-select
            v-if="cities"
            v-model="selected_regency_id"
            placeholder="Kabupaten/Kota"
            dense
            :disabled="role === ROLES.PublicHealthService_Regency"
            item-text="kabupaten_name"
            item-value="kabupatenKota_id"
            :items="cities"
            hide-details="auto"
            outlined
            class="mt-1"
          />
          <v-select
            v-else
            placeholder="Kabupaten/Kota"
            dense
            disabled
            hide-details="auto"
            outlined
          />
        </div>
        <div class="toolbar_items">
          <span class="dropdown-label">Fasyankes</span>
          <v-select
            v-if="cities"
            v-model="selected_fasyankes_type"
            placeholder="Fasyankes"
            dense
            item-text="label"
            item-value="key"
            :items="fasyankes_types"
            hide-details="auto"
            outlined
            class="mt-1"
          />
        </div>
        <div class="align-self-end">
          <v-btn
            class="mx-2 rounded-lg custom_shadow"
            color="primary"
            height="40px"
            dark
            min-width="10px"
            @click="fetchReportsWithReset"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>

      <div style="width: 100%;">
        <v-row class="secondary-toolbar">
          <div
            style="width: 310px;"
            class="mr-1"
          >
            <v-text-field
              v-model="search_query"
              flat
              label="Cari Fasyankes"
              class="custom_field"
              background-color="#F1FBFC"
              solo
              hide-details="auto"
              prepend-inner-icon="mdi-magnify"
              color="blue"
              @keydown="fetchReports"
            />
          </div>
          <div
            style="width: 141px;"
            class="ml-1"
          >
            <v-menu
              v-model="menu_limit"
              bottom
              :nudge-bottom="55"
              elevation="0"
            >
              <template #activator="{ on, attrs }">
                <v-card
                  class="pa-3 d-flex align-center"
                  outlined
                  v-bind="attrs"
                  height="100%"
                  v-on="on"
                >
                  <p class="mx-auto ma-0 color_txt">
                    Show : {{ limit }}
                  </p>
                  <v-icon class="mx-auto">
                    mdi-chevron-down
                  </v-icon>
                </v-card>
              </template>
              <v-card
                class="popup_radius"
                outlined
                elevation="0"
              >
                <v-list>
                  <v-list-item
                    link
                    @click="onUpdateLimit(10)"
                  >
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="onUpdateLimit(50)"
                  >
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="onUpdateLimit(100)"
                  >
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-row>
      </div>

      <v-skeleton-loader
        v-if="loading"
        type="table-tbody"
      />

      <div
        v-if="!loading && waste_emergence_analysis"
        class="my-5"
      >
        <v-data-table
          item-key="id"
          :headers="headers"
          :items="waste_emergence_analysis.data"
          class="elevation-0 color_txt"
          :items-per-page="limit"
          hide-default-footer
          :item-class="row_classes"
        />

        <div class="d-flex pa-3">
          <div class="bottom-bar mx-auto ">
            <div class="d-flex align-center mx-auto pa-2 round_paginate">
              <v-btn
                small
                min-width="10px"
                color="#00b4cc"
                dark
                class="pa-2 custom_shadow"
                @click="pagination('prev')"
              >
                <v-icon small>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <div class="mx-3">
                <p class="ma-0 color_default">
                  {{ page }} of {{ waste_emergence_analysis.last_page }}
                </p>
              </div>
              <v-btn
                small
                min-width="10px"
                color="#00b4cc"
                dark
                class="pa-2 custom_shadow"
                @click="pagination('next')"
              >
                <v-icon small>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

  
<script>
import { mapState } from "vuex";
import { ROLES, FASYANKES_TYPE, FASYANKES_TYPE_NAME } from "../../../../constants/constant"

export default {
  name: "AnalysisTabTimbulanLimbah",
  data() {
    return {
      ROLES,
      FASYANKES_TYPE,
      FASYANKES_TYPE_NAME,
      loading: false,
      fasyankes_types: [
        { key: "", label: "Semua Fasyankes" },
        ...Object.entries(FASYANKES_TYPE_NAME).map(([key, label]) => ({
          key,
          label,
        })),
      ],
      is_loading: false,
      users: [],
      provinces: [],
      cities: [],
      selected_province_id: null,
      selected_regency_id: null,
      date: this.$date().format("YYYY-MM"),
      search_query: "",
      page: 1,
      limit: 10,
      total_page: 0,
      menu_date: false,
      menu_limit: false,
      menu_service: false,
      menu_fasyankes_type: false,
      selected_fasyankes_type: "",
      fasyankes_profile: null,
      view_detail: false,
      viewDetailKesling: false,
      isianData: null,
      show_modal_summary: false,
      modalDialogRekapData: false,
      modalDialogUnduhData: false,
      selected_report: null,
      selected_report_id: null,
      selected_report_status: null,
      selected_service: null,
      typeKesling: "kesehatan lingkungan pertanyaan kunci",
      menu_filter_domain: false,
      selected_filter_domain: null,
      headers: [
        {
          text: "Fasyankes",
          align: "start",
          value: "fasyankes_name",
        },
        {
          text: "Limbah Infeksius (kg/hari)",
          align: "center",
          value: "waste_emergence.medic_infectious",
          width: "200px",
        },
        {
          text: "Limbah Noninfeksius (kg/hari)",
          align: "center",
          value: "waste_emergence.medic_non_infectious",
          width: "200px",
        },

      ],
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      profile: (state) => state.user.profile,
      waste_emergence_analysis: (state) =>
        state.report.waste_emergence_analysis,
      waste_emergence_summary: (state) => state.report.waste_emergence_summary,
    }),
  },
  watch: {
    
  },
  async mounted() {
    await this.getProvinces();
    if (this.profile.access_right === ROLES.PublicHealthService_Province) {
      this.selected_province_id = parseInt(this.profile.provinsi);
      this.selected_role = ROLES.PublicHealthService_Regency;
      await this.getCities();
    }

    if (this.profile.access_right === ROLES.PublicHealthService_Regency) {
      this.selected_province_id = parseInt(this.profile.provinsi);
      this.selected_regency_id = parseInt(this.profile.kota);
      this.selected_role = ROLES.PublicHealthService_Regency;
      await this.getCities();
    }

    this.fetchReports();
    this.fetchSummary();
    
  },
  methods: {
    async fetchSummary() {
        this.is_loading = true;
        await this.$store.dispatch("report/getWasteEmergenceSummary");
        this.is_loading = false;
    },
    getProvinces() {
      this.$store.dispatch("region/getProvinces").then((data) => {
        this.provinces = data.data;
        const default_province = {
          provinsi_id: "",
          provinsi_name: "SEMUA (NASIONAL)",
        };
        this.provinces.unshift(default_province);
      });
    },
    getCities() {
      if (!this.selected_province_id || this.selected_province_id === "") {
        this.selected_regency_id = null;
        this.cities = [];
        return;
      }
      const payload = {
        provinsi_id: this.selected_province_id,
      };
      this.$store.dispatch("region/getCities", payload).then((data) => {
        let cities = data.data;
        if (cities?.length > 0) {
          const all_city = {
            kabupatenKota_id: "",
            kabupaten_name: "SEMUA (KABUPATEN/KOTA)",
          };
          cities?.unshift(all_city);
        }
        this.cities = cities;
      });
    },
    async fetchReports() {
      this.is_loading = true;
      const request_params = {
        page: this.page,
        limit: this.limit,
        search_query: this.search_query,
        province_id: this.selected_province_id,
        regency_id: this.selected_regency_id,
        fasyankes_type: this.selected_fasyankes_type,
      };
      await this.$store.dispatch(
        "report/getWasteEmergenceAnalysisReports",
        request_params
      );
      this.is_loading = false;
    },
    async fetchReportsWithReset() {
      this.page = 1;
      this.limit = 10;
      this.search_query = "";
      this.fetchReports();
    },
    onUpdateLimit(limit) {
      this.menu_limit = false;
      this.limit = limit;
      this.fetchReports();
    },
    row_classes(item) {
      let idx = this.waste_emergence_analysis.data?.indexOf(item);
      if (idx % 2 == 0) {
        return "strip_blue";
      }
    },
    pagination(type) {
      if (type === "prev") {
        if (this.page > 1) {
          this.page--;
          this.fetchReports();
        }
      } else if (type === "next") {
        if (
          this.page < this.waste_emergence_analysis.last_page
        ) {
          this.page++;
          this.fetchReports();
        }
      }
    },
  },
};
</script>
  
  <style scoped>
.v-btn__content {
  min-width: none !important;
  width: 30px !important;
}
.round_paginate {
  height: max-content;
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}
.bottom-bar {
  width: calc(100% / 3);
}
.toolbar_items {
  width: calc(90% / 4);
  padding-right: 10px;
}

.border-top {
  border-top: 1px solid #ccc;
}
.border-bottom {
  border-bottom: 1px solid #ccc;
}

.border-right {
  border-right: 1px solid #ccc;
}

.secondary-toolbar {
  display: flex;
  gap: 10px;

  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
}
</style> 