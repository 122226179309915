<template>
    <div>
        <v-card style="width: 1050px; !important" class="radius_intab custom_card pa-5">
            <div class="d-flex" style="height: 527px; width: 1000px;">
                 <iframe class="w-100" style=" border: 0;" src="https://lookerstudio.google.com/embed/u/0/reporting/0d8aea23-7eda-45f2-a48b-60433f0a319c/page/p_x7pih3496c" frameborder="0"  allowfullscreen></iframe>
            </div>
        </v-card>
    </div>
    
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "AnalysisTabPenyehatanLingkungan",
    data() {
        return {};
    },
    computed: {
    ...mapState({
      role: (state) => state.role,
      profile: (state) => state.user.profile,
    }),
  },
};
</script>

<style scoped>
* {
  font-family: "Monserrat";
}

.v-btn__content {
  min-width: none !important;
  width: 30px !important;
}
.label-province {
  font-weight: 500;
}
.round_paginate {
  height: max-content;
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}
.confirm {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #64ccb7;
  margin-right: 10px;
}
.repair {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #59b5cc;
  margin-right: 10px;
}
.sent {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d7d7d7;
  margin-right: 10px;
}
.unsend {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #f4cb08;
  margin-right: 10px;
}
.nofill {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d92b2a;
  margin-right: 10px;
}
.bottom-bar {
  width: calc(100% / 3);
}
.toolbar_items {
  width: calc(90% / 5);
  padding-right: 10px;
}
</style> 