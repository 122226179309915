<template>
  <v-row justify="start">
    <v-dialog v-model="modalDialogUnduhData" persistent max-width="630px">
      <v-card class="rounded-max hide_overflow" flat>
        <div class="closer d-flex justify-end pa-1">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <div style="min-height: 500px">
          <div class="pa-3">
            <div class="px-5">
              <v-row>
                <v-col class="py-2" cols="12">
                  <label for="" class="color_default"><b>Unduh Data</b></label>
                  <br /><br />
                  <div class="d-flex align-center per-form-added">
                    <label class="label-tipe-1 col-md-5" for="limbah-organik"
                      >Rentang Waktu</label
                    >
                    <v-row>
                      <div
                        class="toolbar_items"
                        style="width: 150px; padding-left: 12px"
                      >
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              append-icon="mdi-calendar"
                              placeholder="--- ------"
                              readonly
                              outlined
                              dense
                              hide-details
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            :type="tipeDate"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </div>
                      &nbsp;
                      <div class="toolbar_items" style="width: 142px">
                        <v-menu
                          ref="menu1"
                          v-model="menu1"
                          :close-on-content-click="false"
                          :return-value.sync="date1"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date1"
                              append-icon="mdi-calendar"
                              placeholder="--- ------"
                              readonly
                              outlined
                              dense
                              hide-details
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date1"
                            :type="tipeDate"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu1 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu1.save(date1)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </div>
                    </v-row>
                  </div>
                  <div
                    class="d-flex align-center per-form-added"
                    style="height: 0px"
                  >
                    <label
                      class="label-tipe-1 col-md-5"
                      for="limbah-organik"
                    ></label>
                    <input type="checkbox" v-model="dateAllTime" />&nbsp;
                    <label for="">Semua Waktu</label>
                  </div>
                  <div
                    class="d-flex align-center per-form-added"
                    v-if="role === 'Administrator' || role === 'Kemenkes'"
                  >
                    <label class="label-tipe-1 col-md-5" for="limbah-organik"
                      >Provinsi</label
                    >
                    <div class="toolbar_items" style="width: 100%">
                      <v-select
                        placeholder="Provinsi"
                        dense
                        item-text="provinsi_name"
                        item-value="provinsi_id"
                        v-model="selectedProvince"
                        @change="getCity"
                        :items="listProvince"
                        hide-details="auto"
                        outlined
                      ></v-select>
                    </div>
                  </div>
                  <div class="d-flex align-center per-form-added">
                    <label class="label-tipe-1 col-md-5" for="limbah-organik"
                      >Kabupaten/Kota</label
                    >
                    <div class="toolbar_items" style="width: 100%">
                      <v-select
                        v-if="listCity"
                        placeholder="Kabupaten/Kota"
                        dense
                        item-text="kabupaten_name"
                        item-value="kabupatenKota_id"
                        v-model="selectedCity"
                        :items="listCity"
                        :readonly="listCity <= 0"
                        hide-details="auto"
                        outlined
                      ></v-select>
                    </div>
                  </div>
                  <div class="d-flex align-center per-form-added">
                    <label class="label-tipe-1 col-md-5" for="limbah-organik"
                      >Fasyankes</label
                    >
                    <div class="toolbar_items" style="width: 100%">
                      <v-select
                        placeholder="Semua"
                        dense
                        item-text="nama_fasyankes"
                        item-value="fasyankes"
                        v-model="selectedFasyankes"
                        :items="listFasyankes"
                        hide-details="auto"
                        outlined
                      ></v-select>
                    </div>
                  </div>
                  <div class="d-flex align-center per-form-added">
                    <label class="label-tipe-1 col-md-5" for="limbah-organik"
                      >Email</label
                    >
                    <input
                        placeholder="Email"
                        type="email"
                        class="pa-2 bordered_input"
                        style="width:100%;"
                        v-model="email"
                      />
                  </div>
                </v-col>
              </v-row>
              <br /><br />
              <div class="d-flex justify-center mt-1">
                <v-btn
                  dark
                  depressed
                  color="#00b4cc"
                  class="text-capitalize"
                  style="border-radius: 25px"
                  @click="download()"
                >
                  DOWNLOAD
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="#00b4cc"
          ></v-progress-circular>
          <b class="color_default">Loading...</b>
        </v-overlay>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

export default {
  name: "dialogUnduhData",
  props: ["modalDialogUnduhData", "tipeForm", "tipeDate"],

  computed: {
    ...mapState({
      role: (state) => state.role,
      person: (state) => state.user.profile,
    }),
  },
  data() {
    return {
      photoProfile: null,
      date: null,
      menu: false,
      date1: null,
      menu1: false,
      dateAllTime: false,
      email: "",
      listProvince: [],
      selectedProvince: "",
      listCity: [],
      selectedCity: "",
      listFasyankes: [
        { nama_fasyankes: "Semua", fasyankes: "" },
        { nama_fasyankes: "Rumah Sakit", fasyankes: 1 },
        { nama_fasyankes: "Puskesmas", fasyankes: 2 },
      ],
      selectedFasyankes: "",
      loading: false,
    };
  },
  mounted() {
    if (this.role === "Administrator" || this.role === "Kemenkes") {
      this.getProvince();
    } else {
      this.selectedProvince = this.person.provinsi;
      this.getCity();
    }
  },
  methods: {
    getProvince() {
      this.$store.dispatch("region/getProvince").then((data) => {
        this.listProvince = data.data;
        let nasional = {
          provinsi_id: "",
          provinsi_name: "Semua (Nasional)",
        };
        this.listProvince.unshift(nasional);
      });
    },
    getCity() {
      this.selectedCity = "";
      let data = {
        provinsi_id: this.selectedProvince,
      };
      this.$store.dispatch("region/getCity", data).then((data) => {
        this.listCity = data.data;
      });
    },
    download() {
      console.log(this.role);
      this.loading = true;
      var url = "";
      // url += process.env.VUE_APP_ENV
      url += "/api/unduh_data?";
      var filter = [];
      if (this.selectedProvince) {
        filter.push("provinsi=" + this.selectedProvince);
      } else {
        filter.push("provinsi=");
      }
      if (this.selectedCity) {
        filter.push("kota=" + this.selectedCity);
      } else {
        filter.push("kota=");
      }
      if (this.selectedFasyankes) {
        filter.push("tipe_fasyankes=" + this.selectedFasyankes);
      } else {
        filter.push("tipe_fasyankes=");
      }
      if (this.date && !this.dateAllTime) {
        filter.push("start_date=" + this.date);
      } else {
        filter.push("start_date=");
      }
      if (this.date1 && !this.dateAllTime) {
        filter.push("end_date=" + this.date1);
      } else {
        filter.push("end_date=");
      }
      if (this.email) {
        filter.push("email=" + this.email);
      } else {
        filter.push("email=");
      }
      filter.push("tipe_form=" + this.tipeForm);
      url += filter.join("&");
      // window.open(url, '_blank');

      let data = {
        url: url,
        tipeForm: this.tipeForm,
      };
      this.$store
        .dispatch("data/downloadData", data)
        .then((data) => {
          console.log(data);
          // location.href = data;
          this.loading = false;
          this.Swal(
            "success",
            "Export excel sedang di proses, Excel akan dikirim via email jika sudah selesai di proses"
          );
          this.email = ""
          this.$emit("close");
        })
        .catch((err) => {
          console.log(err);
          this.Swal("error", "error!");
          this.loading = false;
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
.side_left {
  width: 40%;
  height: 400px;
  border-radius: 0 !important;
}
.side_right {
  width: 60%;
}
.closer {
  width: 100%;
}
.temp_form {
  padding: 20px 25px;
}
.hide_overflow {
  overflow: hidden;
}
.family {
  font-family: Arial, Helvetica, sans-serif;
}
.small-txt {
  font-size: small !important;
}
.bordered_input {
  border-width: 1px;
  border-color: rgba(215, 215, 215, 1); 
  border-style: solid; 
  border-radius:4px;
}
</style>
