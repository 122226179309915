<template>
  <v-card
    class="summary-header__container"
    flat
  >
    <div
      class="summary-header__title"
    >
      {{ title }}
    </div>
    <div class="d-flex align-center">
      <div
        v-if="valid"
        class="summary-header__description"
      >
        <IconCheckBox /> {{ description }}
      </div>
      <div
        v-else
        class="summary-header__description"
      >
        <IconCloseBox /> Tidak {{ description }}
      </div>
    </div>
  </v-card>
</template>

<script>
import IconCheckBox from "../../../../../../components/icons/CheckBox.vue";
import IconCloseBox from "../../../../../../components/icons/CloseBox.vue";

export default {
  name: "DetailSummaryHeader",
  components: { IconCheckBox, IconCloseBox },
  props: {
    valid: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.summary-header__container {
  display: flex;
  align-content: center;
  width: 100%;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  background: #e6f6f9;
}

.summary-header__title {
  padding: 8px;
  padding-left: 20px;

  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: white;
  width: 40%;
  background-color: #00b4cc;
}

.summary-header__description {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  padding-left: 20px;


  font-weight: 450;
  font-size: 14px;
  line-height: 140%;
  color: #404852;
}
</style>