<template>
  <svg
    width="88"
    height="106"
    viewBox="0 0 88 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.3247 35.2681C74.0617 29.5174 70.5961 23.8832 66.9345 18.376L64.3686 14.524L61.7711 18.2497C61.6127 18.5023 58.1599 23.6173 54.1051 30.4373C54.0327 30.2834 53.948 30.1355 53.8517 29.9952C45.9007 16.0396 37.6645 3.97832 37.6645 3.85202L35.1304 0L32.5645 3.85202C32.5645 3.85202 24.3917 16.0396 16.409 30.1215C5.51195 49.3501 6.90456e-05 63.0847 6.90456e-05 70.9151C-0.015866 78.9293 2.72686 86.7065 7.77032 92.9481C12.8138 99.1897 19.8527 103.518 27.712 105.21C35.5712 106.903 43.775 105.857 50.9537 102.247C58.1324 98.6371 63.8516 92.682 67.1562 85.3759C72.8962 84.6825 78.1837 81.9211 82.0229 77.6118C85.8621 73.3025 87.9879 67.7427 88 61.9796C88 56.6752 84.5155 47.9292 77.3247 35.2681ZM35.1304 99.7421C27.4625 99.7337 20.111 96.6939 14.689 91.2896C9.26701 85.8853 6.21723 78.5579 6.20885 70.9151C6.20885 58.4434 25.2153 26.7116 35.1304 11.2403C39.3435 17.7446 45.1721 27.1852 50.4939 36.8468C44 48.6554 40.8323 56.8962 40.8323 61.9796C40.843 67.5182 42.8063 72.8768 46.3789 77.1189C49.9516 81.3609 54.9064 84.2165 60.3773 85.1865C57.8402 89.6246 54.1656 93.3111 49.7294 95.8687C45.2933 98.4262 40.2551 99.763 35.1304 99.7421ZM69.342 78.556C67.6999 79.0589 65.9915 79.3144 64.2736 79.3137C63.7567 79.3459 63.2383 79.3459 62.7214 79.3137C58.3995 78.9133 54.3823 76.9222 51.4538 73.7288C48.5253 70.5355 46.8956 66.3692 46.8827 62.0428C46.8827 58.0645 50.0504 50.8972 53.9468 43.5089C55.0871 41.3303 56.3226 39.1201 57.558 36.9731C59.9021 32.8369 62.3096 28.9533 64.3053 25.8591C66.2376 29.0165 69.0569 33.4368 71.8445 38.4886C80.4291 53.6125 81.7279 59.801 81.7279 62.1375C81.6839 65.8315 80.4592 69.4152 78.2317 72.3679C76.0043 75.3205 72.8902 77.4883 69.342 78.556Z"
      fill="#5DBCDF"
    />
  </svg>

</template>

<script>
export default {
  name: "IconWater",
};
</script>